import _ from "lodash";
// import router from "@/router";
import API_MODULES_PERMISSION from "@/api/apiServices/SystemManagement/permission.js";
import API_MODULES_CORPORATION from "@/api/apiServices/SystemManagement/corporation.js";
import INPUT_OPTIONS from "@/config/inputOptions/SystemManagement";

const state = {
  isPermissionApiCalled: false,
  userIDs: null,
  companyIDOfHeadOffice: null,
  allPermissionItemsOfCurrentAccount: [],
  allDetailsOfPermissionItemsOfCurrentAccount: [],
  listOfPermissionIDs: [],
  listOfActualGroupsAndRelatedAccounts: null,
  listOfActualGroups: null,
  listOfActualItems: null,
  listOfTemplateGroups: null,
  listOfTemplateItems: null,
  listOfSysModules: null,
  listOfSysMenus: null,
  listOfSysFunctions: null,
  listOfCorporations: null,
  listOfBrands: null,
  listOfCompanies: null,
  listOfSubsidiaries: null,
  listOfDepartments: null,
  listOfDepartmentLevelSettings: null,
  listOfGeneralDepartments: null,
  listOfStoreDepartments: null,
};

const getters = {
  /**
   * 重構實際權限項目，按module模組→menu選單→function功能進行層級上的歸類。
   */
  getRefactoredAllPermissionItemsOfCurrentAccount:
    () => (allPermissionItemsOfCurrentAccount) => {
      const groupedData = {};
      Object.values(allPermissionItemsOfCurrentAccount).forEach(
        (item, index) => {
          const { moduleId, menuId, functionId } = item;
          if (!groupedData[moduleId]) {
            groupedData[moduleId] = {};
          }
          if (!groupedData[moduleId][menuId]) {
            groupedData[moduleId][menuId] = {};
          }
          if (!groupedData[moduleId][menuId][functionId]) {
            groupedData[moduleId][menuId][functionId] = {};
          }
          groupedData[moduleId][menuId][functionId][index] = item;
        }
      );
      return groupedData;
    },
  /**
   * 根據`refactoredAllPermissionItemsOfCurrentAccount`，檢索當前帳戶的所有許可項目，並返回包含詳細模組、菜單和功能信息的結構化數據。
   */
  getAllPermissionItemsOfCurrentAccountWithDetails:
    (state) => (moduleObject) => {
      let moduleLists = [];

      // 遍歷模組物件，獲取每個模組的詳細信息和相關菜單、功能信息
      for (const moduleID in moduleObject) {
        let moduleList = {};
        // 獲取模組的基本信息
        const moduleInfo = state.listOfSysModules?.find(
          (module) => module["id"] === moduleID
        );
        moduleList["moduleId"] = moduleID;
        moduleList["moduleName"] = moduleInfo?.twName || null;
        moduleList["moduleShortCode"] = moduleInfo?.shortCode || null;

        const menuObject = moduleObject[moduleID];
        moduleList["menuLists"] = [];

        // 遍歷模組下的菜單物件，獲取每個菜單的詳細信息和相關功能信息
        for (const menuID in menuObject) {
          let menuList = {};
          // 獲取菜單的基本信息
          const menuInfo = state.listOfSysMenus?.find(
            (menu) => menu["id"] === menuID
          );
          menuList["menuId"] = menuID;
          menuList["menuName"] = menuInfo?.twName || null;
          menuList["menuShortCode"] = menuInfo?.shortCode || null;

          const functionObject = menuObject[menuID];
          menuList["functionLists"] = [];

          // 遍歷菜單下的功能物件，獲取每個功能的詳細信息和權限設置
          for (const functionID in functionObject) {
            let functionList = {};
            // 獲取功能的基本信息
            const functionInfo = state.listOfSysFunctions?.find(
              (func) => func["id"] === functionID
            );
            // 從所有當前帳戶的許可項目中找到對應功能的權限設置
            const permissionInfo = Object.values(
              state.allPermissionItemsOfCurrentAccount
            )?.find(
              (permissionItem) => permissionItem?.functionId === functionID
            );

            // 構建包含功能信息和權限設置的功能列表項目
            functionList = {
              ...functionInfo,
              permissionItemId: permissionInfo.permissionItemId,
              functionId: functionID,
              functionName: functionInfo?.twName || null,
              functionShortCode: functionInfo?.shortCode || null,
              canCreate: permissionInfo.create,
              canRead: permissionInfo.read,
              canUpdate: permissionInfo.update,
              canDelete: permissionInfo.delete,
            };
            menuList.functionLists.push(functionList); // 將功能列表項目添加到菜單的功能列表中
          }
          moduleList.menuLists.push(menuList); // 將菜單列表項目添加到模組的菜單列表中
        }
        moduleLists.push(moduleList); // 將模組列表項目添加到模組列表數組中
      }
      return moduleLists; // 返回結構化的模組列表數組
    },
  getMatchedModule: (state) => (twName) => {
    const list = _.cloneDeep(state.allDetailsOfPermissionItemsOfCurrentAccount);
    const matchingModule = list?.find((module) => {
      return module.menuLists.some((menu) => {
        return menu.functionLists.some((func) => {
          return func.twName === twName;
        });
      });
    });
    if (matchingModule) {
      const filteredMenuLists = matchingModule.menuLists.map((menu) => {
        const matchingFunction = menu.functionLists?.find(
          (func) => func.twName === twName
        );
        return matchingFunction
          ? {
              ...menu,
              functionLists: [matchingFunction], // 將匹配的 function 放入一個數組中
            }
          : null; // 如果沒有匹配的 function，則返回 null
      });
      // 更新匹配的 module
      matchingModule.menuLists = filteredMenuLists.filter(Boolean); // 過濾掉為 null 的項
    }
    return matchingModule;
  },
  getMatchedFunction: (state, getters) => (twName) => {
    const matchedModule = getters.getMatchedModule(twName);
    if (matchedModule) {
      const matchingFunction = getters
        .getMatchedModule(twName)
        .menuLists.reduce((matchedFunc, menu) => {
          // 在每个 menu 的 functionLists 中查找匹配的 function
          const func = menu.functionLists?.find(
            (func) => func.twName === twName
          );
          // 如果找到匹配的 function，则返回它
          if (func) {
            return func;
          }
          return matchedFunc; // 如果没找到匹配的 function，则返回之前找到的匹配的 function
        }, null);

      return matchingFunction;
    }
    return null; // 如果没有匹配的 module，则返回 null
  },
  /**
   * 根據功能名稱，辨別使用者的操作權限。
   * @param {string} twName - 功能的中文名稱
   * @returns {Object}
   */
  getUserActionPermissions: (state, getters) => (twName) => {
    const responseData = getters.getMatchedFunction(twName);
    return {
      canCreate: responseData?.canCreate,
      canRead: responseData?.canRead,
      canUpdate: responseData?.canUpdate,
      canDelete: responseData?.canDelete,
    };
  },
  /**
   * 根據功能名稱，辨別該功能所開放的操作功能性。
   * @param {string} twName - 功能的中文名稱
   * @returns {Object}
   */
  getTableActionFunctionalities: (state, getters) => (twName) => {
    const responseData = getters.getMatchedFunction(twName);
    return {
      hasCreate: responseData?.create,
      hasRead: responseData?.read,
      hasUpdate: responseData?.update,
      hasDelete: responseData?.delete,
    };
  },
  /**
   * 根据用户权限和表格功能性检查是否显示特定操作按钮。
   * @function showButtonForAction
   * @memberof SystemManagement/getters
   * @param {Object} state - Vuex 状态对象。
   * @param {Object} getters - Vuex getters 对象。
   * @param {Object} rootState - Vuex 根状态对象。
   * @returns {Function} 返回一个函数，根据传入的操作类型返回布尔值。
   */
  showButtonForAction: (state, getters, rootState) => (action) => {
    const tableActionFunctionalities = getters.getTableActionFunctionalities(
      rootState.currentRoute?.meta.title.split(" - ")[0]
    );
    const userActionPermissions = getters.getUserActionPermissions(
      rootState.currentRoute?.meta.title.split(" - ")[0]
    );

    switch (action) {
      case "Create":
        return (
          tableActionFunctionalities.hasCreate &&
          userActionPermissions.canCreate
        );
      case "Read":
        return (
          tableActionFunctionalities.hasRead && userActionPermissions.canRead
        );
      case "Update":
        return (
          tableActionFunctionalities.hasUpdate &&
          userActionPermissions.canUpdate
        );
      case "Delete":
        return (
          tableActionFunctionalities.hasDelete &&
          userActionPermissions.canDelete
        );
      default:
        return false;
    }
  },
  /**
   * 根據listOfDepartments的`levelSerialNo`獲取對應完整的departmentLevel信息。
   * 再合併levelProperty「單位」到listOfDepartments中的每個部門。
   * @returns {Array}
   */
  getAndMergeLevelPropertyFromDepartmentLevelSetting:
    (state) => (listOfDepartments) => {
      const mergedData = _.map(listOfDepartments, (department) => {
        const matchedLevel = _.find(state.listOfDepartmentLevelSettings, {
          serialNo: department["levelSerialNo"],
        });
        if (matchedLevel) {
          return {
            ...department,
            levelProperty: matchedLevel["levelProperty"],
          };
        }
        return department;
      });
      return mergedData;
    },
};

const mutations = {
  SET_IS_PERMISSION_API_CALLED(state, bool) {
    state.isPermissionApiCalled = bool;
  },
  SET_USER_IDS(state, IDs) {
    state.userIDs = IDs;
  },
  SET_ALL_PERMISSION_ITEMS_OF_CURRENT_ACCOUNT(state, list) {
    state.allPermissionItemsOfCurrentAccount = list;
  },
  SET_ALL_DETAILS_OF_PERMISSION_ITEMS_OF_CURRENT_ACCOUNT(state, data) {
    state.allDetailsOfPermissionItemsOfCurrentAccount = data;
  },
  SET_LIST_OF_PERIMISSION_ITEM_IDS(state, list) {
    state.listOfPermissionIDs = list;
  },
  SET_LIST_OF_ACTUAL_GROUPS_AND_RELATED_ACCOUNTS(state, list) {
    state.listOfActualGroupsAndRelatedAccounts = list;
  },
  SET_LIST_OF_ACTUAL_GROUPS(state, list) {
    state.listOfActualGroups = list;
  },
  SET_LIST_OF_ACTUAL_ITEMS(state, list) {
    state.listOfActualItems = list;
  },
  SET_LIST_OF_TEMPLATE_GROUPS(state, list) {
    state.listOfTemplateGroups = list;
  },
  SET_LIST_OF_TEMPLATE_ITEMS(state, list) {
    state.listOfTemplateItems = list;
  },
  SET_LIST_OF_SYS_MODULES(state, list) {
    state.listOfSysModules = list;
  },
  SET_LIST_OF_SYS_MENUS(state, list) {
    state.listOfSysMenus = list;
  },
  SET_LIST_OF_SYS_FUNCTIONS(state, list) {
    state.listOfSysFunctions = list;
  },
  SET_LIST_OF_CORPORATIONS(state, list) {
    state.listOfCorporations = list;
  },
  SET_LIST_OF_BRANDS(state, list) {
    state.listOfBrands = list;
  },
  SET_LIST_OF_COMPANIES(state, list) {
    state.listOfCompanies = list;
  },
  SET_LIST_OF_SUBSIDIARIES(state, list) {
    state.listOfSubsidiaries = list;
  },
  SET_LIST_OF_DEPARTMENTS(state, list) {
    state.listOfDepartments = list;
    state.listOfGeneralDepartments = _.filter(list, { isStore: false });
    state.listOfStoreDepartments = _.filter(list, { isStore: true });
  },
  SET_LIST_OF_DEPARTMENT_LEVEL_SETTINGS(state, list) {
    state.listOfDepartmentLevelSettings = list;
  },
  SET_COMPANY_ID_OF_HEAD_OFFICE(state) {
    function getCompanyOf(companies, companyTypeName) {
      return companies?.find(
        (company) =>
          company.companyType ===
          INPUT_OPTIONS.Company.companyType?.find(
            (item) => item.name === companyTypeName
          )?.id
      );
    }
    const list = _.cloneDeep(state.listOfCompanies);
    state.companyIDOfHeadOffice = getCompanyOf(list, "總公司")?.serialNo;
  },
};

const actions = {
  /**
   * 在完成「新增」、「修改」、「刪除」操作以後，需立即請求並且更新相關資料。
   * 最後重新獲取當前賬號的所有權限資料，即時更新畫面資料（不需要額外刷新頁面）。
   * @returns {Promise<void>}
   */
  async refreshDataByAction({ dispatch }, action) {
    await dispatch(action, { root: true });
    await dispatch("getAllDetailsOfPermissionItemsByAccountID");
  },
  // GET ALL RELATED IDS BY USER'S ID (ACCOUNT NAME)
  async getUserIDs({ commit }) {
    const responseData = await API_MODULES_PERMISSION.UserIds.getByUserID();
    commit("SET_USER_IDS", responseData);
  },
  /**
   * 根據當前帳戶的帳號ID，獲取所有相關的權限項目詳細信息。
   * @returns {Promise<void>}
   */
  async getAllDetailsOfPermissionItemsByAccountID({
    getters,
    dispatch,
    commit,
  }) {
    commit("SET_IS_PERMISSION_API_CALLED", false);
    // STEP 1: 獲取當前帳戶的所有權限項目
    const allPermissionItemsOfCurrentAccount =
      await API_MODULES_PERMISSION.AllPermissionItemsByAccountID.getAll();
    const filteredAllPermissionItemsOfCurrentAccount = _.filter(
      allPermissionItemsOfCurrentAccount,
      (item) => item["permissionItemId"] !== "AUTHORITY-MGMT"
    );
    const listOfPermissionIDs = await allPermissionItemsOfCurrentAccount.map(
      (item) => item["permissionItemId"]
    );
    await commit(
      "SET_ALL_PERMISSION_ITEMS_OF_CURRENT_ACCOUNT",
      filteredAllPermissionItemsOfCurrentAccount
    );
    commit("SET_LIST_OF_PERIMISSION_ITEM_IDS", listOfPermissionIDs);
    // STEP 2: 按module模組→menu選單→function功能進行層級上的歸類
    const refactoredAllPermissionItemsOfCurrentAccount =
      await getters.getRefactoredAllPermissionItemsOfCurrentAccount(
        filteredAllPermissionItemsOfCurrentAccount
      );
    // STEP 3: 合并module模組、menu菜單、function功能的相關資料
    const allPermissionItemsOfCurrentAccountWithDetails =
      await getters.getAllPermissionItemsOfCurrentAccountWithDetails(
        refactoredAllPermissionItemsOfCurrentAccount
      );
    await dispatch(
      "sortAllPermissionItemsOfCurrentAccountAccordingToModuleMenuFunction",
      allPermissionItemsOfCurrentAccountWithDetails
    );
    commit("SET_IS_PERMISSION_API_CALLED", true);
  },
  sortAllPermissionItemsOfCurrentAccountAccordingToModuleMenuFunction(
    { rootState, commit },
    allPermissionItemsOfCurrentAccountWithDetails
  ) {
    //   const allPermissionItemsOfCurrentAccountWithDetails = [
    //     {
    //       0: menuLists：[
    //         {
    //           0: functionLists：[
    //             {0: functionID，shortCode}，
    //             {1: functionID，shortCode}，
    //           ]
    //         }，
    //         {
    //           1: functionList：[
    //             {0: functionID，shortCode}，
    //             {1: functionID，shortCode}，
    //           ]
    //         }，
    //         menuID：
    //         menuShortCode：
    //     }，
    //     {
    //       1: menuLists：[
    //         {
    //           0: functionLists：[
    //             {0: functionID，shortCode}，
    //             {1: functionID，shortCode}，
    //           ]
    //         }，
    //         {
    //           1: functionList：[
    //             {0: functionID，shortCode}，
    //             {1: functionID，shortCode}，
    //           ]
    //         }，
    //         menuID：
    //         menuShortCode：
    //     }，
    // ]
    //

    // 第一：按照 moduleShortCode 排序
    const sortedByModuleShortCode = _.orderBy(
      allPermissionItemsOfCurrentAccountWithDetails,
      "moduleShortCode",
      "asc"
    );
    // 第二：在每個 module 中按照 menuShortCode 排序
    const sortedByMenuShortCode = sortedByModuleShortCode.map((moduleItem) => {
      const sortedMenuLists = _.orderBy(
        moduleItem.menuLists,
        "menuShortCode",
        "asc"
      );
      return { ...moduleItem, menuLists: sortedMenuLists };
    });
    // 第三：在每個 menu 中按照 functionShortCode 排序
    const sortedByFunctionShortCode = sortedByMenuShortCode.map(
      (moduleItem) => {
        const updatedMenuLists = moduleItem.menuLists.map((menuItem) => {
          const sortedFunctionLists = _.orderBy(
            menuItem.functionLists,
            "functionShortCode",
            "asc"
          );
          return { ...menuItem, functionLists: sortedFunctionLists };
        });
        return { ...moduleItem, menuLists: updatedMenuLists };
      }
    );
    // console.log("sortedByMenuShortCode: ", sortedByMenuShortCode);
    // console.log("sortedByFunctionShortCode: ", sortedByFunctionShortCode);
    commit(
      "SET_ALL_DETAILS_OF_PERMISSION_ITEMS_OF_CURRENT_ACCOUNT",
      sortedByFunctionShortCode
    );
    console.log("token: ", rootState.auth.token);
    console.log("本人權限: ", sortedByFunctionShortCode);
  },
  // ACTUAL GROUP AND RELATED ACCOUNT
  async getAllActualGroupsAndRelatedAccounts({ commit }) {
    const responseData =
      await API_MODULES_PERMISSION.ActualGroupAndRelatedAccount.getAll();
    commit(
      "SET_LIST_OF_ACTUAL_GROUPS_AND_RELATED_ACCOUNTS",
      _.defaultTo(responseData, [])
    );
  },
  async createActualGroupAndRelatedAccount({ rootState, dispatch }, data) {
    const requestData = { ...data, token: rootState.auth.token };
    const responseData =
      await API_MODULES_PERMISSION.ActualGroupAndRelatedAccount.create(
        requestData
      );
    await dispatch(
      "refreshDataByAction",
      "getAllActualGroupsAndRelatedAccounts"
    );
    return responseData;
  },
  async deleteActualGroupAndRelatedAccount({ rootState, dispatch }, data) {
    const requestData = { ...data, token: rootState.auth.token };
    const responseData =
      await API_MODULES_PERMISSION.ActualGroupAndRelatedAccount.delete(
        requestData
      );
    await dispatch(
      "refreshDataByAction",
      "getAllActualGroupsAndRelatedAccounts"
    );
    return responseData;
  },
  // ACTUAL GROUP
  async getAllActualGroups({ commit }) {
    const responseData = await API_MODULES_PERMISSION.ActualGroup.getAll();
    commit("SET_LIST_OF_ACTUAL_GROUPS", _.defaultTo(responseData, []));
  },
  async createActualGroup({ rootState, dispatch }, data) {
    const requestData = { ...data, token: rootState.auth.token };
    const responseData = await API_MODULES_PERMISSION.ActualGroup.create(
      requestData
    );
    await dispatch("refreshDataByAction", "getAllActualGroups");
    return responseData;
  },
  async updateActualGroup({ rootState, dispatch }, data) {
    const requestData = { ...data, token: rootState.auth.token };
    const responseData = await API_MODULES_PERMISSION.ActualGroup.update(
      requestData
    );
    await dispatch("refreshDataByAction", "getAllActualGroups");
    return responseData;
  },
  async deleteActualGroup({ rootState, dispatch }, data) {
    const requestData = { ...data, token: rootState.auth.token };
    const responseData = await API_MODULES_PERMISSION.ActualGroup.delete(
      requestData
    );
    await dispatch("refreshDataByAction", "getAllActualGroups");
    return responseData;
  },
  // ACTUAL ITEM
  async getAllActualItems({ commit }) {
    const responseData = await API_MODULES_PERMISSION.ActualItem.getAll();
    // 過濾掉"AUTHORITY-MGMT"（最高權限管理）不授予任何權限，只能透過DB修改
    const filteredResponseData = _.filter(
      responseData,
      (data) => data["permissionItemId"] !== "AUTHORITY-MGMT"
    );
    commit("SET_LIST_OF_ACTUAL_ITEMS", _.defaultTo(filteredResponseData, []));
  },
  async createActualItem({ rootState, dispatch }, data) {
    const requestData = { ...data, token: rootState.auth.token };
    const responseData = await API_MODULES_PERMISSION.ActualItem.create(
      requestData
    );
    await dispatch("refreshDataByAction", "getAllActualItems");
    return responseData;
  },
  async readActualItemByID(context, id) {
    const responseData = await API_MODULES_PERMISSION.ActualItem.read(id);
    return responseData;
  },
  async updateActualItem({ rootState, dispatch }, data) {
    const requestData = { ...data, token: rootState.auth.token };
    const responseData = await API_MODULES_PERMISSION.ActualItem.update(
      requestData
    );
    await dispatch("refreshDataByAction", "getAllActualItems");
    return responseData;
  },
  async deleteActualItem({ rootState, dispatch }, data) {
    const requestData = { ...data, token: rootState.auth.token };
    const responseData = await API_MODULES_PERMISSION.ActualItem.delete(
      requestData
    );
    await dispatch("refreshDataByAction", "getAllActualItems");
    return responseData;
  },
  // TEMPLATE GROUP
  async getAllTemplateGroups({ commit }) {
    const responseData = await API_MODULES_PERMISSION.TemplateGroup.getAll();
    commit("SET_LIST_OF_TEMPLATE_GROUPS", _.defaultTo(responseData, []));
  },
  async createTemplateGroup({ rootState, dispatch }, data) {
    const requestData = { ...data, token: rootState.auth.token };
    const responseData = await API_MODULES_PERMISSION.TemplateGroup.create(
      requestData
    );
    await dispatch("refreshDataByAction", "getAllTemplateGroups");
    return responseData;
  },
  async updateTemplateGroup({ rootState, dispatch }, data) {
    const requestData = { ...data, token: rootState.auth.token };
    const responseData = await API_MODULES_PERMISSION.TemplateGroup.update(
      requestData
    );
    await dispatch("refreshDataByAction", "getAllTemplateGroups");
    return responseData;
  },
  async deleteTemplateGroup({ rootState, dispatch }, data) {
    const requestData = { ...data, token: rootState.auth.token };
    const responseData = await API_MODULES_PERMISSION.TemplateGroup.delete(
      requestData
    );
    await dispatch("refreshDataByAction", "getAllTemplateGroups");
    return responseData;
  },
  // TEMPLATE ITEM
  async getAllTemplateItems({ commit }) {
    const responseData = await API_MODULES_PERMISSION.TemplateItem.getAll();
    commit("SET_LIST_OF_TEMPLATE_ITEMS", _.defaultTo(responseData, []));
  },
  async createTemplateItem({ rootState, dispatch }, data) {
    const requestData = { ...data, token: rootState.auth.token };
    const responseData = await API_MODULES_PERMISSION.TemplateItem.create(
      requestData
    );
    await dispatch("refreshDataByAction", "getAllTemplateItems");
    return responseData;
  },
  async updateTemplateItem({ rootState, dispatch }, data) {
    const requestData = { ...data, token: rootState.auth.token };
    const responseData = await API_MODULES_PERMISSION.TemplateItem.update(
      requestData
    );
    await dispatch("refreshDataByAction", "getAllTemplateItems");
    return responseData;
  },
  async deleteTemplateItem({ rootState, dispatch }, data) {
    const requestData = { ...data, token: rootState.auth.token };
    const responseData = await API_MODULES_PERMISSION.TemplateItem.delete(
      requestData
    );
    await dispatch("refreshDataByAction", "getAllTemplateItems");
    return responseData;
  },
  // MODULE
  async getAllSysModules({ commit }) {
    const responseData = await API_MODULES_PERMISSION.SysModule.getAll();
    commit("SET_LIST_OF_SYS_MODULES", _.defaultTo(responseData, []));
  },
  async createSysModule({ rootState, dispatch }, data) {
    const requestData = { ...data, token: rootState.auth.token };
    const responseData = await API_MODULES_PERMISSION.SysModule.create(
      requestData
    );
    await dispatch("refreshDataByAction", "getAllSysModules");
    return responseData;
  },
  async readSysModule(context, id) {
    const responseData = await API_MODULES_PERMISSION.SysModule.read(id);
    return responseData;
  },
  async updateSysModule({ rootState, dispatch }, data) {
    const requestData = { ...data, token: rootState.auth.token };
    const responseData = await API_MODULES_PERMISSION.SysModule.update(
      requestData
    );
    await dispatch("refreshDataByAction", "getAllSysModules");
    return responseData;
  },
  async deleteSysModule({ rootState, dispatch }, data) {
    const requestData = { ...data, token: rootState.auth.token };
    const responseData = await API_MODULES_PERMISSION.SysModule.delete(
      requestData
    );
    await dispatch("refreshDataByAction", "getAllSysModules");
    return responseData;
  },
  // MENU
  async getAllSysMenus({ commit }) {
    const responseData = await API_MODULES_PERMISSION.SysMenu.getAll();
    // 過濾掉 "SM10_000"（最高權限管理）不授予任何權限，只能透過 DB 修改
    const filteredResponseData = _.filter(
      responseData,
      (data) => data["id"] !== "SM10_000"
    );
    commit("SET_LIST_OF_SYS_MENUS", _.defaultTo(filteredResponseData, []));
  },
  async createSysMenu({ rootState, dispatch }, data) {
    const requestData = { ...data, token: rootState.auth.token };
    const responseData = await API_MODULES_PERMISSION.SysMenu.create(
      requestData
    );
    await dispatch("refreshDataByAction", "getAllSysMenus");
    return responseData;
  },
  async readSysMenu(context, id) {
    const responseData = await API_MODULES_PERMISSION.SysMenu.read(id);
    return responseData;
  },
  async updateSysMenu({ rootState, dispatch }, data) {
    const requestData = { ...data, token: rootState.auth.token };
    const responseData = await API_MODULES_PERMISSION.SysMenu.update(
      requestData
    );
    await dispatch("refreshDataByAction", "getAllSysMenus");
    return responseData;
  },
  async deleteSysMenu({ rootState, dispatch }, data) {
    const requestData = { ...data, token: rootState.auth.token };
    const responseData = await API_MODULES_PERMISSION.SysMenu.delete(
      requestData
    );
    await dispatch("refreshDataByAction", "getAllSysMenus");
    return responseData;
  },
  // FUNCTION
  async getAllSysFunctions({ commit }) {
    const responseData = await API_MODULES_PERMISSION.SysFunction.getAll();
    // 過濾掉 "000_0"（最高權限管理）不授予任何權限，只能透過 DB 修改
    const filteredResponseData = _.filter(
      responseData,
      (data) => data["id"] !== "000_0"
    );
    commit("SET_LIST_OF_SYS_FUNCTIONS", _.defaultTo(filteredResponseData, []));
  },
  async createSysFunction({ rootState, dispatch }, data) {
    const requestData = { ...data, token: rootState.auth.token };
    const responseData = await API_MODULES_PERMISSION.SysFunction.create(
      requestData
    );
    await dispatch("refreshDataByAction", "getAllSysFunctions");
    return responseData;
  },
  async readSysFunction(context, id) {
    const responseData = await API_MODULES_PERMISSION.SysFunction.read(id);
    return responseData;
  },
  async updateSysFunction({ rootState, dispatch }, data) {
    const requestData = { ...data, token: rootState.auth.token };
    const responseData = await API_MODULES_PERMISSION.SysFunction.update(
      requestData
    );
    await dispatch("refreshDataByAction", "getAllSysFunctions");
    return responseData;
  },
  async deleteSysFunction({ rootState, dispatch }, data) {
    const requestData = { ...data, token: rootState.auth.token };
    const responseData = await API_MODULES_PERMISSION.SysFunction.delete(
      requestData
    );
    await dispatch("refreshDataByAction", "getAllSysFunctions");
    return responseData;
  },
  // CORPORATION
  async getAllCorporations({ state, rootGetters, commit }) {
    const permissionID = rootGetters.getPermissionID(state.listOfPermissionIDs);
    const responseData = await API_MODULES_CORPORATION.Corporation.getAll(
      permissionID
    );
    commit("SET_LIST_OF_CORPORATIONS", _.defaultTo(responseData, []));
  },
  async createCorporation({ rootState, rootGetters, dispatch }, data) {
    const requestData = { ...data, token: rootState.auth.token };
    const permissionID = rootGetters.getPermissionID(state.listOfPermissionIDs);
    const responseData = await API_MODULES_CORPORATION.Corporation.create(
      requestData,
      permissionID
    );
    await dispatch("refreshDataByAction", "getAllCorporations");
    return responseData;
  },
  async readCorporation(context, id) {
    const responseData = await API_MODULES_CORPORATION.Corporation.read(id);
    return responseData;
  },
  async updateCorporation({ rootState, rootGetters, dispatch }, data) {
    const requestData = { ...data, token: rootState.auth.token };
    const permissionID = rootGetters.getPermissionID(state.listOfPermissionIDs);
    const responseData = await API_MODULES_CORPORATION.Corporation.update(
      requestData,
      permissionID
    );
    await dispatch("refreshDataByAction", "getAllCorporations");
    return responseData;
  },
  async deleteCorporation({ rootState, rootGetters, dispatch }, data) {
    const requestData = { ...data, token: rootState.auth.token };
    const permissionID = rootGetters.getPermissionID(state.listOfPermissionIDs);
    const responseData = await API_MODULES_CORPORATION.Corporation.delete(
      requestData,
      permissionID
    );
    await dispatch("refreshDataByAction", "getAllCorporations");
    return responseData;
  },
  // BRAND
  async getAllBrands({ rootGetters, commit }) {
    const permissionID = rootGetters.getPermissionID(state.listOfPermissionIDs);
    const responseData = await API_MODULES_CORPORATION.Brand.getAll(
      permissionID
    );
    commit("SET_LIST_OF_BRANDS", _.defaultTo(responseData, []));
  },
  async createBrand({ rootState, rootGetters, dispatch }, data) {
    const requestData = { ...data, token: rootState.auth.token };
    const permissionID = rootGetters.getPermissionID(state.listOfPermissionIDs);
    const responseData = await API_MODULES_CORPORATION.Brand.create(
      requestData,
      permissionID
    );
    await dispatch("refreshDataByAction", "getAllBrands");
    return responseData;
  },
  async updateBrand({ rootState, rootGetters, dispatch }, data) {
    const requestData = { ...data, token: rootState.auth.token };
    const permissionID = rootGetters.getPermissionID(state.listOfPermissionIDs);
    const responseData = await API_MODULES_CORPORATION.Brand.update(
      requestData,
      permissionID
    );
    await dispatch("refreshDataByAction", "getAllBrands");
    return responseData;
  },
  async deleteBrand({ rootState, rootGetters, dispatch }, data) {
    const requestData = { ...data, token: rootState.auth.token };
    const permissionID = rootGetters.getPermissionID(state.listOfPermissionIDs);
    const responseData = await API_MODULES_CORPORATION.Brand.delete(
      requestData,
      permissionID
    );
    await dispatch("refreshDataByAction", "getAllBrands");
    return responseData;
  },
  // COMPANY
  async getAllCompanies({ rootGetters, commit }) {
    const permissionID = rootGetters.getPermissionID(state.listOfPermissionIDs);
    const responseData = await API_MODULES_CORPORATION.Company.getAll(
      permissionID
    );
    commit("SET_LIST_OF_COMPANIES", _.defaultTo(responseData, []));
    commit("SET_COMPANY_ID_OF_HEAD_OFFICE");
  },
  async createCompany({ rootState, rootGetters, dispatch }, data) {
    const requestData = { ...data, token: rootState.auth.token };
    const permissionID = rootGetters.getPermissionID(state.listOfPermissionIDs);
    const responseData = await API_MODULES_CORPORATION.Company.create(
      requestData,
      permissionID
    );
    await dispatch("refreshDataByAction", "getAllCompanies");
    return responseData;
  },
  async updateCompany({ rootState, rootGetters, dispatch }, data) {
    const requestData = { ...data, token: rootState.auth.token };
    const permissionID = rootGetters.getPermissionID(state.listOfPermissionIDs);
    const responseData = await API_MODULES_CORPORATION.Company.update(
      requestData,
      permissionID
    );
    await dispatch("refreshDataByAction", "getAllCompanies");
    return responseData;
  },
  async deleteCompany({ rootState, rootGetters, dispatch }, data) {
    const requestData = { ...data, token: rootState.auth.token };
    const permissionID = rootGetters.getPermissionID(state.listOfPermissionIDs);
    const responseData = await API_MODULES_CORPORATION.Company.delete(
      requestData,
      permissionID
    );
    await dispatch("refreshDataByAction", "getAllCompanies");
    return responseData;
  },
  // SUBSIDIARY
  async getAllSubsidiaries({ rootGetters, commit }) {
    const permissionID = rootGetters.getPermissionID(state.listOfPermissionIDs);
    const responseData = await API_MODULES_CORPORATION.Subsidiary.getAll(
      permissionID
    );
    commit("SET_LIST_OF_SUBSIDIARIES", _.defaultTo(responseData, []));
  },
  async createSubsidiary({ rootState, rootGetters, dispatch }, data) {
    const requestData = { ...data, token: rootState.auth.token };
    const responseData = await API_MODULES_CORPORATION.Subsidiary.create(
      requestData,
      permissionID
    );
    const permissionID = rootGetters.getPermissionID(state.listOfPermissionIDs);
    await dispatch("refreshDataByAction", "getAllSubsidiaries");
    return responseData;
  },
  async updateSubsidiary({ rootState, rootGetters, dispatch }, data) {
    const requestData = { ...data, token: rootState.auth.token };
    const permissionID = rootGetters.getPermissionID(state.listOfPermissionIDs);
    const responseData = await API_MODULES_CORPORATION.Subsidiary.update(
      requestData,
      permissionID
    );
    await dispatch("refreshDataByAction", "getAllSubsidiaries");
    return responseData;
  },
  async deleteSubsidiary({ rootState, rootGetters, dispatch }, data) {
    const requestData = { ...data, token: rootState.auth.token };
    const permissionID = rootGetters.getPermissionID(state.listOfPermissionIDs);
    const responseData = await API_MODULES_CORPORATION.Subsidiary.delete(
      requestData,
      permissionID
    );
    await dispatch("refreshDataByAction", "getAllSubsidiaries");
    return responseData;
  },
  // DEPARTMENT
  async getAllDepartments({ rootGetters, getters, commit, dispatch }) {
    const permissionID = rootGetters.getPermissionID(state.listOfPermissionIDs);
    const responseData = await API_MODULES_CORPORATION.Department.getAll(
      permissionID
    );
    await dispatch("getAllDepartmentLevelSettings");
    const mergedData =
      getters.getAndMergeLevelPropertyFromDepartmentLevelSetting(responseData);
    await commit("SET_LIST_OF_DEPARTMENTS", _.defaultTo(mergedData, []));
  },
  async createDepartment({ rootState, rootGetters, dispatch }, data) {
    const requestData = { ...data, token: rootState.auth.token };
    const permissionID = rootGetters.getPermissionID(state.listOfPermissionIDs);
    const responseData = await API_MODULES_CORPORATION.Department.create(
      requestData,
      permissionID
    );
    await dispatch("refreshDataByAction", "getAllDepartments");
    return responseData;
  },
  async updateDepartment({ rootState, rootGetters, dispatch }, data) {
    const requestData = { ...data, token: rootState.auth.token };
    const permissionID = rootGetters.getPermissionID(state.listOfPermissionIDs);
    const responseData = await API_MODULES_CORPORATION.Department.update(
      requestData,
      permissionID
    );
    await dispatch("refreshDataByAction", "getAllDepartments");
    return responseData;
  },
  async deleteDepartment({ rootState, rootGetters, dispatch }, data) {
    const requestData = { ...data, token: rootState.auth.token };
    const permissionID = rootGetters.getPermissionID(state.listOfPermissionIDs);
    const responseData = await API_MODULES_CORPORATION.Department.delete(
      requestData,
      permissionID
    );
    await dispatch("refreshDataByAction", "getAllDepartments");
    return responseData;
  },
  // DEPARTMENT LEVEL SETTING
  async getAllDepartmentLevelSettings({ rootGetters, commit }) {
    const permissionID = rootGetters.getPermissionID(state.listOfPermissionIDs);
    const responseData =
      await API_MODULES_CORPORATION.DepartmentLevelSetting.getAll(permissionID);
    commit(
      "SET_LIST_OF_DEPARTMENT_LEVEL_SETTINGS",
      _.defaultTo(responseData, [])
    );
  },
  async createDepartmentLevelSetting(
    { rootState, rootGetters, dispatch },
    data
  ) {
    const requestData = { ...data, token: rootState.auth.token };
    const permissionID = rootGetters.getPermissionID(state.listOfPermissionIDs);
    const responseData =
      await API_MODULES_CORPORATION.DepartmentLevelSetting.create(
        requestData,
        permissionID
      );
    await dispatch("refreshDataByAction", "getAllDepartmentLevelSettings");
    return responseData;
  },
  async updateDepartmentLevelSetting(
    { rootState, rootGetters, dispatch },
    data
  ) {
    const requestData = { ...data, token: rootState.auth.token };
    const permissionID = rootGetters.getPermissionID(state.listOfPermissionIDs);
    const responseData =
      await API_MODULES_CORPORATION.DepartmentLevelSetting.update(
        requestData,
        permissionID
      );
    await dispatch("refreshDataByAction", "getAllDepartmentLevelSettings");
    return responseData;
  },
  async deleteDepartmentLevelSetting(
    { rootState, rootGetters, dispatch },
    data
  ) {
    const requestData = { ...data, token: rootState.auth.token };
    const permissionID = rootGetters.getPermissionID(state.listOfPermissionIDs);
    const responseData =
      await API_MODULES_CORPORATION.DepartmentLevelSetting.delete(
        requestData,
        permissionID
      );
    await dispatch("refreshDataByAction", "getAllDepartmentLevelSettings");
    return responseData;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
