import axios from "axios";
// import router from "@/router";
import store from "@/store";

const instance = axios.create({
  baseURL: "http://192.168.2.237:8080/",
});

instance.interceptors.request.use(
  (config) => {
    config.headers.Cookie = document.cookie;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      store.dispatch.setCurrentRouteAndPermissionItemId({
        name: "Login",
      });
    }
    return Promise.reject(error);
  }
);

export default instance;
