import { sharedOptions } from "./sharedOptions";

// const options = {
//   itemType: [
//     { id: 1, name: "模組" },
//     { id: 2, name: "選單" },
//     { id: 3, name: "功能" },
//   ],
//   type: [
//     { id: 1, name: "群組" },
//     { id: 2, name: "職掌" },
//   ],
// };

const ActualGroup = {
  type: [...sharedOptions.type],
  jobTitleId: [...sharedOptions.jobTitleId],
};

const ActualItem = {
  itemType: [...sharedOptions.itemType],
};

const TemplateGroup = {
  type: [...sharedOptions.type],
  jobTitleId: [...sharedOptions.jobTitleId],
};

const TemplateItem = {
  itemType: [...sharedOptions.itemType],
};

const Company = {
  companyType: [
    { id: 1, name: "總公司" },
    { id: 2, name: "公司" },
    { id: 3, name: "關係企業" },
  ],
};

const DepartmentLevelSetting = {
  levelProperty: [
    { id: 1, name: "管理", class: "table-badge-style badge-groupset-1-1" },
    { id: 2, name: "營業", class: "table-badge-style badge-groupset-1-2" },
    { id: 4, name: "廠務", class: "table-badge-style badge-groupset-1-3" },
  ],
};

export default {
  ActualGroup,
  ActualItem,
  TemplateGroup,
  TemplateItem,
  Company,
  DepartmentLevelSetting,
};
