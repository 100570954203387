/**
 * 所有 Web API 的基礎 URL。
 * 此 URL 通過環境變量進行配置。
 * @constant {string}
 */
const BASE_URL = "/api";

/**
 * 所有 Web API 的路徑集合。
 * 這些路徑通過 BASE_URL 來構建，並根據不同的功能模塊進行分類。
 * @constant {Object}
 */
export const API_ROUTES = {
  /**
   * 登入認證相關的 API 路徑。
   * @namespace
   * @property {string} LOGIN - 登入 API 路徑。
   * @property {string} LOGOUT - 登出 API 路徑。
   * @property {string} LOGOUT_ALL - 全部登出 API 路徑。
   * @property {string} REGISTER - 註冊 API 路徑。
   * @property {string} DELETE - 刪除帳戶 API 路徑。
   * @property {string} REVISE_PASSWORD - 修改密碼 API 路徑。
   * @property {string} REVISE_EMAIL - 修改郵箱 API 路徑。
   * @property {string} CHECK_ACCOUNT_LOGIN_BY_TOKEN - 通過 Token 檢查帳戶登錄狀態 API 路徑。
   * @property {string} UPDATE_ACCOUNT_STATUS - 更新帳戶狀態 API 路徑。
   * @property {string} CHANGE_EXISTING_TOKEN_TO_NEW_ONE - 更換 Token API 路徑。
   */
  AUTH: {
    LOGIN: `${BASE_URL}/api/Auth/Login`,
    LOGOUT: `${BASE_URL}/api/Auth/Logout`,
    LOGOUT_ALL: `${BASE_URL}/api/Auth/LogoutAll`,
    REGISTER: `${BASE_URL}/api/Auth/Register`,
    DELETE: `${BASE_URL}/api/Auth/Delete`,
    REVISE_PASSWORD: `${BASE_URL}/api/Auth/RevisePassword`,
    REVISE_EMAIL: `${BASE_URL}/api/Auth/ReviseEmail`,
    CHECK_ACCOUNT_LOGIN_BY_TOKEN: `${BASE_URL}/api/Auth/CheckAccountLoginByToken`,
    UPDATE_ACCOUNT_STATUS: `${BASE_URL}/api/Auth/UpdateAccountStatus`,
    CHANGE_EXISTING_TOKEN_TO_NEW_ONE: `${BASE_URL}/api/Auth/ChangeExistingTokenToNewOne`,
  },
  /**
   * 權限相關的 API 路徑。
   * @namespace
   * @property {string} ALL_PERMISSION_ITEMS_BY_ACCOUNT_ID - 通過帳戶 ID 獲取所有權限項目 API 路徑。
   * @property {string} IS_GRANTED_FOR - 檢查是否授予特定權限 API 路徑。
   * @property {Object} ACTUAL_GROUP - 實際權限組 API 路徑集合。
   * @property {Object} ACTUAL_ITEM - 實際權限項目 API 路徑集合。
   * @property {Object} SYS_MODULE - 系統模塊 API 路徑集合。
   * @property {Object} SYS_MENU - 系統菜單 API 路徑集合。
   * @property {Object} SYS_FUNCTION - 系統功能 API 路徑集合。
   * @property {Object} TEMPLATE_ITEM - 權限項目 API 路徑集合。
   * @property {Object} TEMPLATE_GROUP - 權限模板 API 路徑集合。
   * @property {Object} ACTUAL_GROUP_AND_RELATED_ACCOUNT - 實際群組與相關成員
   */
  PERMISSION: {
    ALL_PERMISSION_ITEMS_BY_ACCOUNT_ID: `${BASE_URL}/api/Permission/GetActualPermissionItemsByAccountId`,
    IS_GRANTED_FOR: `${BASE_URL}/api/Permission/IsGrantedFor`,
    ACTUAL_GROUP: {
      ALL: `${BASE_URL}/api/Permission/ActualPermissionGroup_All`,
      CREATE: `${BASE_URL}/api/Permission/ActualPermissionGroup_Create`,
      READ: `${BASE_URL}/api/Permission/ActualPermissionGroup_Read/{id}`,
      UPDATE: `${BASE_URL}/api/Permission/ActualPermissionGroup_Update`,
      DELETE: `${BASE_URL}/api/Permission/ActualPermissionGroup_Delete`,
    },
    ACTUAL_ITEM: {
      ALL: `${BASE_URL}/api/Permission/ActualPermissionItem_All`,
      CREATE: `${BASE_URL}/api/Permission/ActualPermissionItem_Create`,
      READ: `${BASE_URL}/api/Permission/ActualPermissionItem_Read/{id}`,
      UPDATE: `${BASE_URL}/api/Permission/ActualPermissionItem_Update`,
      DELETE: `${BASE_URL}/api/Permission/ActualPermissionItem_Delete`,
    },
    SYS_MODULE: {
      ALL: `${BASE_URL}/api/Permission/SysModule_All`,
      CREATE: `${BASE_URL}/api/Permission/SysModule_Create`,
      READ: `${BASE_URL}/api/Permission/SysModule_Read/{id}`,
      UPDATE: `${BASE_URL}/api/Permission/SysModule_Update`,
      DELETE: `${BASE_URL}/api/Permission/SysModule_Delete`,
    },
    SYS_MENU: {
      ALL: `${BASE_URL}/api/Permission/SysMenu_All`,
      CREATE: `${BASE_URL}/api/Permission/SysMenu_Create`,
      READ: `${BASE_URL}/api/Permission/SysMenu_Read/{id}`,
      UPDATE: `${BASE_URL}/api/Permission/SysMenu_Update`,
      DELETE: `${BASE_URL}/api/Permission/SysMenu_Delete`,
    },
    SYS_FUNCTION: {
      ALL: `${BASE_URL}/api/Permission/SysFunction_All`,
      CREATE: `${BASE_URL}/api/Permission/SysFunction_Create`,
      READ: `${BASE_URL}/api/Permission/SysFunction_Read/{id}`,
      UPDATE: `${BASE_URL}/api/Permission/SysFunction_Update`,
      DELETE: `${BASE_URL}/api/Permission/SysFunction_Delete`,
    },
    ACTUAL_GROUP_AND_RELATED_ACCOUNT: {
      ALL: `${BASE_URL}/api/Permission/SysPermissionToAccount_All`,
      CREATE: `${BASE_URL}/api/Permission/SysPermissionToAccount_Create`,
      DELETE: `${BASE_URL}/api/Permission/SysPermissionToAccount_Delete`,
    },
    TEMPLATE_ITEM: {
      ALL: `${BASE_URL}/api/Permission/PermissionItemTemplate_All`,
      CREATE: `${BASE_URL}/api/Permission/PermissionItemTemplate_Create`,
      READ: `${BASE_URL}/api/Permission/PermissionItemTemplate_Read/{id}`,
      UPDATE: `${BASE_URL}/api/Permission/PermissionItemTemplate_Update`,
      DELETE: `${BASE_URL}/api/Permission/PermissionItemTemplate_Delete`,
    },
    TEMPLATE_GROUP: {
      ALL: `${BASE_URL}/api/Permission/PermissionGroupTemplate_All`,
      CREATE: `${BASE_URL}/api/Permission/PermissionGroupTemplate_Create`,
      READ: `${BASE_URL}/api/Permission/PermissionGroupTemplate_Read/{id}`,
      UPDATE: `${BASE_URL}/api/Permission/PermissionGroupTemplate_Update`,
      DELETE: `${BASE_URL}/api/Permission/PermissionGroupTemplate_Delete`,
    },
  },
  /**
   * 人事相關的 API 路徑。
   * @namespace
   * @property {string} QUERY_USER_IDS - 查詢用戶 ID API 路徑。
   * @property {string} GENERATE_VERIFICATION_CODE_BY_STAFFID - 根據員工 ID 生成驗證碼 API 路徑。
   * @property {Object} STAFF_RECORD - 員工記錄 API 路徑集合。
   * @property {Object} STAFF_AND_RELATED_DEPARTMENT
   */
  PERSONNEL: {
    QUERY_USER_IDS: `${BASE_URL}/api/Personnel/QueryUserIds`,
    GENERATE_VERIFICATION_CODE_BY_STAFFID: `${BASE_URL}/api/Personnel/GenerateVerificationCodeForStaffInfo/{permissionId}`,
    STAFF_RECORD: {
      ALL: `${BASE_URL}/api/Personnel/StaffRecord_All/{permissionId}`,
      CREATE: `${BASE_URL}/api/Personnel/StaffRecord_Create/{permissionId}`,
      READ: `${BASE_URL}/api/Personnel/StaffRecord_Read`,
      UPDATE: `${BASE_URL}/api/Personnel/StaffRecord_Update`,
      DELETE: `${BASE_URL}/api/Personnel/StaffRecord_Delete/{permissionId}`,
    },
    STAFF_AND_RELATED_DEPARTMENT: {
      ALL: `${BASE_URL}/api/Personnel/StaffToDepts_All/{permissionId}`,
      CREATE: `${BASE_URL}/api/Personnel/StaffToDepts_Create/{permissionId}`,
      READ: `${BASE_URL}/api/Personnel/StaffToDepts_Read`,
      DELETE: `${BASE_URL}/api/Personnel/StaffToDepts_Delete/{permissionId}`,
    },
    JOB_TITLE_SETTING: {
      ALL: `${BASE_URL}/api/Personnel/JobTitleSetting_All/{permissionId}`,
      CREATE: `${BASE_URL}/api/Personnel/JobTitleSetting_Create/{permissionId}`,
      READ: `${BASE_URL}/api/Personnel/JobTitleSetting_Read`,
      UPDATE: `${BASE_URL}/api/Personnel/JobTitleSetting_Update/{permissionId}`,
      DELETE: `${BASE_URL}/api/Personnel/JobTitleSetting_Delete/{permissionId}`,
    },
  },
  /**
   * 企業相關的 API 路徑。
   * @namespace
   * @property {Object} CORPORATION - 企業集團 API 路徑集合。
   * @property {Object} BRAND - 品牌 API 路徑集合。
   * @property {Object} COMPANY - 公司 API 路徑集合。
   * @property {Object} SUBSIDIARY - 子公司 API 路徑集合。
   * @property {Object} DEPARTMENT - 部門 API 路徑集合。
   * @property {Object} DEPARTMENT_LEVEL_SETTING - 部門層級設置 API 路徑集合。
   */
  CORPORATION: {
    CORPORATION: {
      ALL: `${BASE_URL}/api/Corporation/GroupOfEnterprises_All/{permissionId}`,
      CREATE: `${BASE_URL}/api/Corporation/GroupOfEnterprises_Create/{permissionId}`,
      READ: `${BASE_URL}/api/Corporation/GroupOfEnterprises_Read/{id}`,
      UPDATE: `${BASE_URL}/api/Corporation/GroupOfEnterprises_Update/{permissionId}`,
      DELETE: `${BASE_URL}/api/Corporation/GroupOfEnterprises_Delete/{permissionId}`,
    },
    BRAND: {
      ALL: `${BASE_URL}/api/Corporation/Brand_All/{permissionId}`,
      CREATE: `${BASE_URL}/api/Corporation/Brand_Create/{permissionId}`,
      READ: `${BASE_URL}/api/Corporation/Brand_Read/{id}`,
      UPDATE: `${BASE_URL}/api/Corporation/Brand_Update/{permissionId}`,
      DELETE: `${BASE_URL}/api/Corporation/Brand_Delete/{permissionId}`,
    },
    COMPANY: {
      ALL: `${BASE_URL}/api/Corporation/Company_All/{permissionId}`,
      CREATE: `${BASE_URL}/api/Corporation/Company_Create/{permissionId}`,
      READ: `${BASE_URL}/api/Corporation/Company_Read/{id}`,
      UPDATE: `${BASE_URL}/api/Corporation/Company_Update/{permissionId}`,
      DELETE: `${BASE_URL}/api/Corporation/Company_Delete/{permissionId}`,
    },
    SUBSIDIARY: {
      ALL: `${BASE_URL}/api/Corporation/Subsidiary_All/{permissionId}`,
      CREATE: `${BASE_URL}/api/Corporation/Subsidiary_Create/{permissionId}`,
      READ: `${BASE_URL}/api/Corporation/Subsidiary_Read/{id}`,
      UPDATE: `${BASE_URL}/api/Corporation/Subsidiary_Update/{permissionId}`,
      DELETE: `${BASE_URL}/api/Corporation/Subsidiary_Delete/{permissionId}`,
    },
    DEPARTMENT: {
      ALL: `${BASE_URL}/api/Corporation/Department_All/{permissionId}`,
      CREATE: `${BASE_URL}/api/Corporation/Department_Create/{permissionId}`,
      READ: `${BASE_URL}/api/Corporation/Department_Read/{id}`,
      UPDATE: `${BASE_URL}/api/Corporation/Department_Update/{permissionId}`,
      DELETE: `${BASE_URL}/api/Corporation/Department_Delete/{permissionId}`,
    },
    DEPARTMENT_LEVEL_SETTING: {
      ALL: `${BASE_URL}/api/Corporation/DepartmentLevelSettings_All/{permissionId}`,
      CREATE: `${BASE_URL}/api/Corporation/DepartmentLevelSettings_Create/{permissionId}`,
      READ: `${BASE_URL}/api/Corporation/DepartmentLevelSettings_Read/{id}`,
      UPDATE: `${BASE_URL}/api/Corporation/DepartmentLevelSettings_Update/{permissionId}`,
      DELETE: `${BASE_URL}/api/Corporation/DepartmentLevelSettings_Delete/{permissionId}`,
    },
  },
  /**
   * 商品相關的 API 路徑。
   * @namespace
   * @property {Object} CATEGORY - 商品大分類 API 路徑集合。
   * @property {Object} CLASS - 商品小分類 API 路徑集合。
   * @property {Object} MODEL - 商品品項 API 路徑集合。
   * @property {Object} ATTRIBUTE_TYPE - 商品定義類型 API 路徑集合。
   * @property {Object} ATTRIBUTE_VALUE- 商品定義類型設定值 API 路徑集合。
   * @property {Object} POST_PROCESSING_TYPE - 商品後加工類型 API 路徑集合。
   * @property {Object} POST_PROCESSING_PRIMARY_VALUE - 商品後加工類型設定值 API 路徑集合。
   * @property {Object} CATALOG - 商品目錄 API 路徑集合。
   */
  PRODUCT: {
    CATEGORY: {
      ALL: `${BASE_URL}/api/Product/ProductCategory_All`,
      CREATE: `${BASE_URL}/api/Product/ProductCategory_Create/{permissionId}`,
      READ: `${BASE_URL}/api/Product/ProductCategory_Read/{id}`,
      UPDATE: `${BASE_URL}/api/Product/ProductCategory_Update/{permissionId}`,
      DELETE: `${BASE_URL}/api/Product/ProductCategory_Delete/{permissionId}`,
    },
    CLASS: {
      ALL: `${BASE_URL}/api/Product/ProductClass_All`,
      CREATE: `${BASE_URL}/api/Product/ProductClass_Create/{permissionId}`,
      READ: `${BASE_URL}/api/Product/ProductClass_Read/{id}`,
      UPDATE: `${BASE_URL}/api/Product/ProductClass_Update/{permissionId}`,
      DELETE: `${BASE_URL}/api/Product/ProductClass_Delete/{permissionId}`,
    },
    MODEL: {
      ALL: `${BASE_URL}/api/Product/ProductType_All`,
      CREATE: `${BASE_URL}/api/Product/ProductType_Create/{permissionId}`,
      READ: `${BASE_URL}/api/Product/ProductType_Read/{id}`,
      UPDATE: `${BASE_URL}/api/Product/ProductType_Update/{permissionId}`,
      DELETE: `${BASE_URL}/api/Product/ProductType_Delete/{permissionId}`,
    },
    ATTRIBUTE_TYPE: {
      ALL: `${BASE_URL}/api/Product/ProductAttribute_All`,
      CREATE: `${BASE_URL}/api/Product/ProductAttribute_Create/{permissionId}`,
      READ: `${BASE_URL}/api/Product/ProductAttribute_Read/{id}`,
      UPDATE: `${BASE_URL}/api/Product/ProductAttribute_Update/{permissionId}`,
      DELETE: `${BASE_URL}/api/Product/ProductAttribute_Delete/{permissionId}`,
    },
    ATTRIBUTE_VALUE: {
      ALL: `${BASE_URL}/api/Product/ProductAttributeValues_All`,
      CREATE: `${BASE_URL}/api/Product/ProductAttributeValues_Create/{permissionId}`,
      READ: `${BASE_URL}/api/Product/ProductAttributeValues_Read/{id}`,
      UPDATE: `${BASE_URL}/api/Product/ProductAttributeValues_Update/{permissionId}`,
      DELETE: `${BASE_URL}/api/Product/ProductAttributeValues_Delete/{permissionId}`,
    },
    POST_PROCESSING_TYPE: {
      ALL: `${BASE_URL}/api/Product/ProductPostProcessing_All`,
      CREATE: `${BASE_URL}/api/Product/ProductPostProcessing_Create/{permissionId}`,
      READ: `${BASE_URL}/api/Product/ProductPostProcessing_Read/{id}`,
      UPDATE: `${BASE_URL}/api/Product/ProductPostProcessing_Update/{permissionId}`,
      DELETE: `${BASE_URL}/api/Product/ProductPostProcessing_Delete/{permissionId}`,
    },
    POST_PROCESSING_PRIMARY_VALUE: {
      ALL: `${BASE_URL}/api/Product/ProductPostProcessingValues_All`,
      CREATE: `${BASE_URL}/api/Product/ProductPostProcessingValues_Create/{permissionId}`,
      READ: `${BASE_URL}/api/Product/ProductPostProcessingValues_Read/{id}`,
      UPDATE: `${BASE_URL}/api/Product/ProductPostProcessingValues_Update/{permissionId}`,
      DELETE: `${BASE_URL}/api/Product/ProductPostProcessingValues_Delete/{permissionId}`,
    },
    POST_PROCESSING_SECONDARY_VALUE: {
      ALL: `${BASE_URL}/api/Product/ProductPostProcessingPrimaryValues_All`,
      CREATE: `${BASE_URL}/api/Product/ProductPostProcessingPrimaryValues_Create/{permissionId}`,
      READ: `${BASE_URL}/api/Product/ProductPostProcessingPrimaryValues_Read/{id}`,
      UPDATE: `${BASE_URL}/api/Product/ProductPostProcessingPrimaryValues_Update/{permissionId}`,
      DELETE: `${BASE_URL}/api/Product/ProductPostProcessingPrimaryValues_Delete/{permissionId}`,
    },
    POST_PROCESSING_TERTIARY_VALUE: {
      ALL: `${BASE_URL}/api/Product/ProductPostProcessingSecondaryValues_All`,
      CREATE: `${BASE_URL}/api/Product/ProductPostProcessingSecondaryValues_Create/{permissionId}`,
      READ: `${BASE_URL}/api/Product/ProductPostProcessingSecondaryValues_Read/{id}`,
      UPDATE: `${BASE_URL}/api/Product/ProductPostProcessingSecondaryValues_Update/{permissionId}`,
      DELETE: `${BASE_URL}/api/Product/ProductPostProcessingSecondaryValues_Delete/{permissionId}`,
    },
    POST_PROCESSING_QUATERNARY_VALUE: {
      ALL: `${BASE_URL}/api/Product/ProductPostProcessingTertiaryValues_All`,
      CREATE: `${BASE_URL}/api/Product/ProductPostProcessingTertiaryValues_Create/{permissionId}`,
      READ: `${BASE_URL}/api/Product/ProductPostProcessingTertiaryValues_Read/{id}`,
      UPDATE: `${BASE_URL}/api/Product/ProductPostProcessingTertiaryValues_Update/{permissionId}`,
      DELETE: `${BASE_URL}/api/Product/ProductPostProcessingTertiaryValues_Delete/{permissionId}`,
    },
    CATALOG: {
      ALL: `${BASE_URL}/api/Product/ProductCatalog_All`,
      CREATE: `${BASE_URL}/api/Product/ProductCatalog_Create/{permissionId}`,
      READ: `${BASE_URL}/api/Product/ProductCatalog_Read/{id}`,
      UPDATE: `${BASE_URL}/api/Product/ProductCatalog_Update/{permissionId}`,
      DELETE: `${BASE_URL}/api/Product/ProductCatalog_Delete/{permissionId}`,
    },
    PRODUCT_WITH_ATTRIBUTE_TYPE_CONFIGURATION: {
      ALL: `${BASE_URL}/api/Product/ProdAttrVal_Master_All`,
      CREATE: `${BASE_URL}/api/Product/ProdAttrVal_Master_Create/{permissionId}`,
      READ: `${BASE_URL}/api/Product/ProdAttrVal_Master_Read/{id}`,
      UPDATE: `${BASE_URL}/api/Product/ProdAttrVal_Master_Update/{permissionId}`,
      DELETE: `${BASE_URL}/api/Product/ProdAttrVal_Master_Delete/{permissionId}`,
    },
    PRODUCT_WITH_ATTRIBUTE_VALUE_CONFIGURATION: {
      ALL: `${BASE_URL}/api/Product/ProdAttrVal_Detail_All`,
      CREATE: `${BASE_URL}/api/Product/ProdAttrVal_Detail_Create/{permissionId}`,
      READ: `${BASE_URL}/api/Product/ProdAttrVal_Detail_Read/{id}`,
      UPDATE: `${BASE_URL}/api/Product/ProdAttrVal_Detail_Update/{permissionId}`,
      DELETE: `${BASE_URL}/api/Product/ProdAttrVal_Detail_Delete/{permissionId}`,
    },
    PRINTING_VENDOR: {
      ALL: `${BASE_URL}/api/Product/CoopVender_All`,
      CREATE: `${BASE_URL}/api/Product/CoopVender_Create/{permissionId}`,
      READ: `${BASE_URL}/api/Product/CoopVender_Read/{id}`,
      UPDATE: `${BASE_URL}/api/Product/CoopVender_Update/{permissionId}`,
      DELETE: `${BASE_URL}/api/Product/CoopVender_Delete/{permissionId}`,
    },
    PRINT_UNIT: {
      ALL: `${BASE_URL}/api/Product/UnitList_All`,
      CREATE: `${BASE_URL}/api/Product/UnitList_Create/{permissionId}`,
      READ: `${BASE_URL}/api/Product/UnitList_Read/{id}`,
      UPDATE: `${BASE_URL}/api/Product/UnitList_Update/{permissionId}`,
      DELETE: `${BASE_URL}/api/Product/UnitList_Delete/{permissionId}`,
    },
  },
};
