<template>
  <BNav
    vertical
    :class="['m-0', 'px-3', 'w-100', 'main-navbar-content', { collapsed }]"
  >
    <BRow>
      <BCol class="col-12">
        <BAlert
          show
          variant="transparent"
          class="mt-2 mb-0 font-weight-bold main-sidebar-alert"
        >
          <span v-show="!collapsed">{{ greetingText }}, {{ userID }}</span>
          <span>
            <BIcon
              class="text-center main-sidebar-toggle"
              @click="toggleSidebar"
              :icon="collapsed ? 'chevron-right' : 'chevron-left'"
            />
          </span>
        </BAlert>
      </BCol>
    </BRow>
    <div class="main-sidebar-divider" />
    <BRow v-for="item in menuItems" :key="item.id">
      <BCol class="col-12">
        <BButton
          v-b-toggle="[`collapse-${item.name}`]"
          variant="transparent"
          class="mt-2 w-100 text-start font-weight-bold main-sidebar-button"
        >
          <div>
            <BIcon :icon="item.icon" shift-v="2" class="mx-2" />
            <span v-if="!collapsed">{{ item.title }}</span>
          </div>
        </BButton>
        <BCollapse
          v-model="buttonCollapseState[item.name]"
          :id="`collapse-${item.name}`"
          class="my-2"
        >
          <BRow>
            <BCol>
              <BButton
                v-b-toggle
                variant="transparent"
                class="ps-5 w-100 text-start main-sidebar-button"
              >
                Submenu 1
              </BButton>
            </BCol>
          </BRow>
          <BRow>
            <BCol>
              <BButton
                v-b-toggle
                variant="transparent"
                class="ps-5 w-100 text-start main-sidebar-button"
              >
                Submenu 2
              </BButton>
            </BCol>
          </BRow>
        </BCollapse>
        <div class="main-sidebar-divider" />
      </BCol>
    </BRow>
  </BNav>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "SideBar",
  props: {
    collapsed: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      buttonCollapseState: {},
      menuItems: [
        { id: 1, name: "Collection1", title: "收藏組1", icon: "star" },
        { id: 2, name: "Collection2", title: "收藏組2", icon: "star" },
      ],
    };
  },
  watch: {
    collapsed(bool) {
      if (bool) this.buttonCollapseState = {};
    },
    buttonCollapseState: {
      deep: true,
      immediate: true,
      handler(newValue) {
        const isMenuOpened = Object.values(newValue).some(
          (state) => state === true
        );
        this.$emit("toggle-sidebar-menu", isMenuOpened);
      },
    },
  },
  computed: {
    ...mapState({
      userID: (state) => state.auth.userID,
    }),
    greetingText() {
      const currentDate = new Date();
      const currentHour = currentDate.getHours();
      if (currentHour < 12) {
        return "早安";
      } else if (currentHour < 18) {
        return "午安";
      } else {
        return "晚安";
      }
    },
  },
  methods: {
    toggleSidebar() {
      this.$emit("toggle-sidebar");
    },
  },
  mounted() {},
};
</script>

<style scoped></style>
