// QUERY_USER_IDS
class QUERY_USER_IDS {
  constructor(
    token = "",
    systemAccountId = "",
    explicitStaffId = "",
    implicitStaffId = 0
  ) {
    this.token = String(token);
    this.systemAccountId = String(systemAccountId);
    this.explicitStaffId = String(explicitStaffId);
    this.implicitStaffId = parseInt(implicitStaffId);
  }
}

// GENERATE VERIFICATION CODE BY STAFF ID
class GENERATE_VERIFICATION_CODE_BY_STAFFID {
  constructor(token = "", staffId = "") {
    this.token = String(token);
    this.staffId = String(staffId);
  }
}

// STAFF_RECORD
class STAFF_RECORD_ALL {
  constructor(token = "") {
    this.token = String(token);
  }
}
class STAFF_RECORD_CREATE {
  constructor(
    token = "",
    staffId = "",
    cnName = "",
    enName = "",
    sex = 0,
    birthDate = "",
    citizenId = "",
    marriageStatus = "",
    telephone = "",
    cellphone = "",
    officialTelephone = "",
    officialCellphone = "",
    permanentAddr = "",
    contactAddr = "",
    emailAddr = "",
    lastEducationalInstitute = "",
    departmentByFaculty = "",
    army = 0,
    jobStatus = 0,
    jobTitleId = 0,
    officialDepartmentId = "",
    lineId = "",
    lineToken = "",
    verificationCode = "",
    implicitStaffId = 0,
    accountId = "",
    serialNo = 0
  ) {
    this.token = String(token);
    this.staffId = String(staffId);
    this.cnName = String(cnName);
    this.enName = String(enName);
    this.sex = parseInt(sex);
    this.birthDate = String(birthDate);
    this.citizenId = String(citizenId);
    this.marriageStatus = String(marriageStatus); // TODO: int
    this.telephone = String(telephone);
    this.cellphone = String(cellphone);
    this.officialTelephone = String(officialTelephone);
    this.officialCellphone = String(officialCellphone);
    this.permanentAddr = String(permanentAddr);
    this.contactAddr = String(contactAddr);
    this.emailAddr = String(emailAddr);
    this.lastEducationalInstitute = lastEducationalInstitute; // TODO: delete
    this.departmentByFaculty = departmentByFaculty; // TODO: delete
    this.army = parseInt(army);
    this.jobStatus = parseInt(jobStatus);
    this.jobTitleId = parseInt(jobTitleId);
    this.officialDepartmentId = String(officialDepartmentId);
    this.lineId = String(lineId);
    this.lineToken = String(lineToken);
    this.verificationCode = String(verificationCode);
    this.implicitStaffId = parseInt(implicitStaffId);
    this.accountId = String(accountId);
    this.serialNo = parseInt(serialNo);
  }
}
class STAFF_RECORD_FAST_CREATE {
  constructor(token = "", staffId = "") {
    this.token = String(token);
    this.staffId = String(staffId);
  }
}
class STAFF_RECORD_READ {
  constructor(token = "", staffId = "", verificationCode = "") {
    this.token = String(token);
    this.staffId = String(staffId);
    this.verificationCode = verificationCode;
  }
}
class STAFF_RECORD_UPDATE {
  constructor(
    token = "",
    staffId = "",
    cnName = "",
    enName = "",
    sex = 0,
    birthDate = "",
    citizenId = "",
    marriageStatus = "",
    telephone = "",
    cellphone = "",
    officialTelephone = "",
    officialCellphone = "",
    permanentAddr = "",
    contactAddr = "",
    emailAddr = "",
    lastEducationalInstitute = "",
    departmentByFaculty = "",
    army = 0,
    jobStatus = 0,
    jobTitleId = 0,
    officialDepartmentId = "",
    lineId = "",
    lineToken = "",
    verificationCode = "",
    implicitStaffId = 0,
    accountId = "",
    serialNo = 0
  ) {
    this.token = String(token);
    this.staffId = String(staffId);
    this.cnName = String(cnName);
    this.enName = String(enName);
    this.sex = parseInt(sex);
    this.birthDate = String(birthDate);
    this.citizenId = String(citizenId);
    this.marriageStatus = String(marriageStatus); // TODO: int
    this.telephone = String(telephone);
    this.cellphone = String(cellphone);
    this.officialTelephone = String(officialTelephone);
    this.officialCellphone = String(officialCellphone);
    this.permanentAddr = String(permanentAddr);
    this.contactAddr = String(contactAddr);
    this.emailAddr = String(emailAddr);
    this.lastEducationalInstitute = lastEducationalInstitute; // TODO: delete
    this.departmentByFaculty = departmentByFaculty; // TODO: delete
    this.army = parseInt(army);
    this.jobStatus = parseInt(jobStatus);
    this.jobTitleId = parseInt(jobTitleId);
    this.officialDepartmentId = String(officialDepartmentId);
    this.lineId = String(lineId);
    this.lineToken = String(lineToken);
    this.verificationCode = String(verificationCode);
    this.implicitStaffId = parseInt(implicitStaffId);
    this.accountId = String(accountId);
    this.serialNo = parseInt(serialNo);
  }
}
class STAFF_RECORD_DELETE {
  constructor(
    token = "",
    staffId = "",
    cnName = "",
    enName = "",
    sex = 0,
    birthDate = "",
    citizenId = "",
    marriageStatus = "",
    telephone = "",
    cellphone = "",
    officialTelephone = "",
    officialCellphone = "",
    permanentAddr = "",
    contactAddr = "",
    emailAddr = "",
    lastEducationalInstitute = "",
    departmentByFaculty = "",
    army = 0,
    jobStatus = 0,
    jobTitleId = 0,
    officialDepartmentId = "",
    lineId = "",
    lineToken = "",
    verificationCode = "",
    implicitStaffId = 0,
    accountId = "",
    serialNo = 0
  ) {
    this.token = String(token);
    this.staffId = String(staffId);
    this.cnName = String(cnName);
    this.enName = String(enName);
    this.sex = parseInt(sex);
    this.birthDate = String(birthDate);
    this.citizenId = String(citizenId);
    this.marriageStatus = String(marriageStatus); // TODO: int
    this.telephone = String(telephone);
    this.cellphone = String(cellphone);
    this.officialTelephone = String(officialTelephone);
    this.officialCellphone = String(officialCellphone);
    this.permanentAddr = String(permanentAddr);
    this.contactAddr = String(contactAddr);
    this.emailAddr = String(emailAddr);
    this.lastEducationalInstitute = lastEducationalInstitute; // TODO: delete
    this.departmentByFaculty = departmentByFaculty; // TODO: delete
    this.army = parseInt(army);
    this.jobStatus = parseInt(jobStatus);
    this.jobTitleId = parseInt(jobTitleId);
    this.officialDepartmentId = String(officialDepartmentId);
    this.lineId = String(lineId);
    this.lineToken = String(lineToken);
    this.verificationCode = String(verificationCode);
    this.implicitStaffId = parseInt(implicitStaffId);
    this.accountId = String(accountId);
    this.serialNo = parseInt(serialNo);
  }
}

// STAFF AND RELATED DEPARTMENT
class STAFF_AND_RELATED_DEPARTMENT_ALL {
  constructor(token = "") {
    this.token = String(token);
  }
}
class STAFF_AND_RELATED_DEPARTMENT_CREATE {
  constructor(
    token = "",
    serialNo = 0,
    staffId = "",
    departmentId = "",
    priority = 0
  ) {
    this.token = String(token);
    this.serialNo = parseInt(serialNo);
    this.staffId = String(staffId);
    this.departmentId = String(departmentId);
    this.priority = parseInt(priority);
  }
}
class STAFF_AND_RELATED_DEPARTMENT_READ {
  constructor(
    token = "",
    serialNo = 0,
    staffId = "",
    departmentId = "",
    priority = 0
  ) {
    this.token = String(token);
    this.serialNo = parseInt(serialNo);
    this.staffId = String(staffId);
    this.departmentId = String(departmentId);
    this.priority = parseInt(priority);
  }
}
class STAFF_AND_RELATED_DEPARTMENT_DELETE {
  constructor(
    token = "",
    serialNo = 0,
    staffId = "",
    departmentId = "",
    priority = 0
  ) {
    this.token = String(token);
    this.serialNo = parseInt(serialNo);
    this.staffId = String(staffId);
    this.departmentId = String(departmentId);
    this.priority = parseInt(priority);
  }
}

// JOB TITLE SETTING
class JOB_TITLE_SETTING_ALL {
  constructor(token = "") {
    this.token = String(token);
  }
}
class JOB_TITLE_SETTING_CREATE {
  constructor(token = "", serialNo = 0, chName = "", jobLevel = "") {
    this.token = String(token);
    this.serialNo = parseInt(serialNo);
    this.chName = String(chName);
    this.jobLevel = String(jobLevel);
  }
}
class JOB_TITLE_SETTING_READ {
  constructor(token = "") {
    this.token = String(token);
  }
}
class JOB_TITLE_SETTING_UPDATE {
  constructor(token = "", serialNo = 0, chName = "", jobLevel = "") {
    this.token = String(token);
    this.serialNo = parseInt(serialNo);
    this.chName = String(chName);
    this.jobLevel = String(jobLevel);
  }
}
class JOB_TITLE_SETTING_DELETE {
  constructor(token = "", serialNo = 0, chName = "", jobLevel = "") {
    this.token = String(token);
    this.serialNo = parseInt(serialNo);
    this.chName = String(chName);
    this.jobLevel = String(jobLevel);
  }
}

export default {
  QUERY_USER_IDS,
  STAFF_RECORD_ALL,
  STAFF_RECORD_CREATE,
  STAFF_RECORD_FAST_CREATE,
  STAFF_RECORD_READ,
  STAFF_RECORD_UPDATE,
  STAFF_RECORD_DELETE,
  STAFF_AND_RELATED_DEPARTMENT_ALL,
  STAFF_AND_RELATED_DEPARTMENT_CREATE,
  STAFF_AND_RELATED_DEPARTMENT_READ,
  STAFF_AND_RELATED_DEPARTMENT_DELETE,
  GENERATE_VERIFICATION_CODE_BY_STAFFID,
  JOB_TITLE_SETTING_ALL,
  JOB_TITLE_SETTING_CREATE,
  JOB_TITLE_SETTING_READ,
  JOB_TITLE_SETTING_UPDATE,
  JOB_TITLE_SETTING_DELETE,
};
