import Vue from "vue";
import _ from "lodash";

const lodashMixin = {
  created() {
    Vue.prototype._ = _;
  },
};

Vue.mixin(lodashMixin);
