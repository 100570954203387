class LOGIN {
  constructor(id = "", pwd = "") {
    this.id = String(id);
    this.pwd = String(pwd);
  }
}

class LOGOUT {
  constructor(token = "") {
    this.token = String(token);
  }
}

class REGISTER {
  constructor(id = "", pwd = "", email = "") {
    this.id = String(id);
    this.pwd = String(pwd);
    this.email = String(email);
  }
}

class CHECK_TOKEN_VALID {
  constructor(token = "") {
    this.token = String(token);
  }
}

export default {
  LOGIN,
  LOGOUT,
  REGISTER,
  CHECK_TOKEN_VALID,
};
