import INPUT_OPTIONS from "@/config/inputOptions/SystemManagement";
import VALIDATOR from "@/config/validator";

const actionExtra = {
  data: {
    field: "btn_act",
    key: "btn_act",
    title: "",
  },
  propertyOfTable: {
    fixed: "right",
    center: "right",
    width: 120,
  },
};

const actionCRUD = {
  data: {
    field: "",
    key: "e",
    title: "",
  },
  propertyOfTable: {
    fixed: "right",
    align: "right",
    width: 200,
  },
};

const implicitStaffId = {
  data: {
    field: "implicitStaffId",
    key: "implicitStaffId",
    title: "系統賬號ID（只能通過DB查詢）",
    inputType: "number",
    validator: VALIDATOR.isValidNumber,
    hasFunctionality: { create: true, edit: true },
    isRequiredToInput: { create: false, edit: false },
  },
  propertyOfTable: {
    align: "left",
    sortBy: "",
    ellipsis: { showTitle: true, lineClamp: 2 },
  },
};

const accountId = {
  data: {
    field: "accountId",
    key: "accountId",
    title: "系統賬號",
    inputType: "text",
    validator: VALIDATOR.isValidString,
    hasFunctionality: { create: true, edit: true },
    isRequiredToInput: { create: false, edit: false },
  },
  propertyOfTable: {
    align: "left",
    sortBy: "",
    ellipsis: { showTitle: true, lineClamp: 2 },
  },
};

const departmentId = {
  data: {
    field: "departmentId",
    key: "departmentId",
    inputType: "option",
    title: "部門代號",
    validator: VALIDATOR.isValidString,
    options: {
      option: [],
      valueField: "departmentId",
      textField: "chName",
    },
    hasFunctionality: { create: true, edit: true },
    isRequiredToInput: { create: true, edit: true },
  },
  propertyOfTable: {
    width: 200,
    align: "left",
    sortBy: "",
    ellipsis: { showTitle: true, lineClamp: 2 },
  },
};

const levelProperty = {
  data: {
    field: "levelProperty",
    key: "levelProperty",
    inputType: "option",
    title: "單位",
    validator: VALIDATOR.isValidNumber,
    options: {
      option: INPUT_OPTIONS.DepartmentLevelSetting.levelProperty,
      valueField: "id",
      textField: "name",
    },
    hasFunctionality: { create: true, edit: true },
    isRequiredToInput: { create: true, edit: true },
  },
  propertyOfTable: {
    align: "left",
    sortBy: "",
    ellipsis: { showTitle: true, lineClamp: 2 },
  },
};

export default {
  accountId,
  actionCRUD,
  actionExtra,
  departmentId,
  implicitStaffId,
  levelProperty,
};
