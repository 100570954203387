var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BContainer',{staticClass:"dashboard-layout",staticStyle:{"margin":"0","padding":"0"}},[_c('NavBar',{on:{"select:module":_vm.handleModuleClicked}}),_c('BContainer',{staticStyle:{"width":"100vw"},attrs:{"fluid":""}},[_c('BRow',[_c('BCol',{class:[
          'main-content',
          {
            'main-sidebar': _vm.$route.name === 'Dashboard',
            'main-sidebar-menu': _vm.$route.name !== 'Dashboard',
          },
          {
            'col-1': _vm.collapsed,
            'col-2': !_vm.collapsed,
          },
        ]},[(_vm.$route.name === 'Dashboard')?_c('SideBar',{attrs:{"collapsed":_vm.collapsed},on:{"toggle-sidebar":_vm.toggleSidebar,"toggle-sidebar-menu":_vm.toggleSidebarMenu}}):_c('SideBarMenu',{attrs:{"collapsed":_vm.collapsed},on:{"toggle-sidebar":_vm.toggleSidebar,"toggle-sidebar-menu":_vm.toggleSidebarMenu,"select:menu":_vm.handleMenuClicked,"select:function":_vm.handleFunctionClicked}})],1),_c('BCol',{class:[
          'main-content',
          {
            'col-11': _vm.collapsed,
            'col-10': !_vm.collapsed,
          },
        ]},[_c('MainContainer',{scopedSlots:_vm._u([{key:"main-content",fn:function(){return [_c('BreadCrumb',{attrs:{"items":_vm.items}})]},proxy:true}])}),_c('router-view')],1)],1),_c('BRow',[_c('BCol',{staticClass:"col-12"})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }