import { sharedOptions } from "../inputOptions/sharedOptions";

const options = {
  sex: [
    { id: 1, name: "女" },
    { id: 2, name: "男" },
  ],
  marriageStatus: [
    { id: 1, name: "未婚" },
    { id: 2, name: "已婚" },
    { id: 3, name: "離婚" },
    { id: 4, name: "喪偶" },
  ],
  lastEducationalInstitute: [
    { id: 1, name: "國小" },
    { id: 2, name: "國中" },
    { id: 3, name: "高中" },
    { id: 4, name: "大學" },
    { id: 5, name: "專科" },
    { id: 6, name: "碩士" },
    { id: 7, name: "博士" },
  ],
  army: [
    { id: 1, name: "役畢" },
    { id: 2, name: "待役" },
    { id: 3, name: "免役" },
  ],
  jobStatus: [
    { id: 1, name: "在職" },
    { id: 2, name: "試用期" },
    { id: 3, name: "留停" },
    { id: 4, name: "離職" },
  ],
  jobFunctionId: [
    { id: 1, name: "正職" },
    { id: 2, name: "專員" },
    { id: 3, name: "高級專員" },
    { id: 4, name: "新進" },
    { id: 5, name: "兼職" },
  ],
  jobGrade: [
    { id: 1, name: "一等" },
    { id: 2, name: "二等" },
    { id: 3, name: "三等" },
  ],
  bloodType: [
    { id: 1, name: "O" },
    { id: 2, name: "A" },
    { id: 3, name: "B" },
    { id: 4, name: "AB" },
    { id: 5, name: "RH" },
  ],
  specialIdentity: [
    { id: 1, name: "無（預設）" },
    { id: 2, name: "外籍人士" },
    { id: 3, name: "身心輕度" },
    { id: 4, name: "身心中度" },
    { id: 5, name: "身心重度" },
  ],
};

const StaffRecord = {
  army: [...options.army],
  bloodType: [...options.bloodType],
  jobFunctionId: [...options.jobFunctionId],
  jobGrade: [...options.jobGrade],
  jobStatus: [...options.jobStatus],
  jobTitleId: [...sharedOptions.jobTitleId],
  lastEducationalInstitute: [...options.lastEducationalInstitute],
  marriageStatus: [...options.marriageStatus],
  sex: [...options.sex],
  specialIdentity: [...options.specialIdentity],
};

export default { StaffRecord };
