// ALL_PERMISSION_ITEMS_BY_ACCOUNT_ID
class ALL_PERMISSION_ITEMS_BY_ACCOUNT_ID {
  constructor(token = "") {
    this.token = String(token);
  }
}

// ACTUAL_GROUP_AND_RELATED_ACCOUNT
class ACTUAL_GROUP_AND_RELATED_ACCOUNT_ALL {
  constructor(token = "") {
    this.token = String(token);
  }
}
class ACTUAL_GROUP_AND_RELATED_ACCOUNT_CREATE {
  constructor(token = "", permissionGroupId = "", accountId = "") {
    this.token = String(token);
    this.permissionGroupId = String(permissionGroupId);
    this.accountId = String(accountId);
  }
}
class ACTUAL_GROUP_AND_RELATED_ACCOUNT_DELETE {
  constructor(token = "", permissionGroupId = "", accountId = "") {
    this.token = String(token);
    this.permissionGroupId = String(permissionGroupId);
    this.accountId = String(accountId);
  }
}

// ACTUAL_GROUP
class ACTUAL_GROUP_ALL {
  constructor(token = "") {
    this.token = String(token);
  }
}
class ACTUAL_GROUP_CREATE {
  constructor(token = "", permissionGroupId = "", groupName = "") {
    this.token = String(token);
    this.permissionGroupId = String(permissionGroupId);
    this.groupName = String(groupName);
  }
}
class ACTUAL_GROUP_READ {
  constructor(token = "") {
    this.token = String(token);
  }
}
class ACTUAL_GROUP_UPDATE {
  constructor(token = "", permissionGroupId = "", groupName = "") {
    this.token = String(token);
    this.permissionGroupId = String(permissionGroupId);
    this.groupName = String(groupName);
  }
}
class ACTUAL_GROUP_DELETE {
  constructor(token = "", permissionGroupId = "", groupName = "") {
    this.token = String(token);
    this.permissionGroupId = String(permissionGroupId);
    this.groupName = String(groupName);
  }
}

// ACTUAL_ITEM
class ACTUAL_PERMISSION_ITEM_ALL {
  constructor(token = "") {
    this.token = String(token);
  }
}
class ACTUAL_PERMISSION_ITEM_CREATE {
  constructor(
    token = "",
    permissionItemId = "",
    itemName = "",
    permissionGroupId = "",
    moduleId = "",
    menuId = "",
    functionId = "",
    itemType = 0,
    actionCreate = false,
    actionRead = false,
    actionUpdate = false,
    actionDelete = false
  ) {
    this.token = String(token);
    this.permissionItemId = String(permissionItemId);
    this.itemName = String(itemName);
    this.permissionGroupId = String(permissionGroupId);
    this.moduleId = String(moduleId);
    this.menuId = String(menuId);
    this.functionId = String(functionId);
    this.itemType = parseInt(itemType);
    this.create = actionCreate;
    this.read = actionRead;
    this.update = actionUpdate;
    this.delete = actionDelete;
  }
}
class ACTUAL_PERMISSION_ITEM_READ {
  constructor(token = "") {
    this.token = String(token);
  }
}
class ACTUAL_PERMISSION_ITEM_UPDATE {
  constructor(
    token = "",
    permissionItemId = "",
    itemName = "",
    permissionGroupId = "",
    moduleId = "",
    menuId = "",
    functionId = "",
    itemType = 0,
    actionCreate = false,
    actionRead = false,
    actionUpdate = false,
    actionDelete = false
  ) {
    this.token = String(token);
    this.permissionItemId = String(permissionItemId);
    this.itemName = String(itemName);
    this.permissionGroupId = String(permissionGroupId);
    this.moduleId = String(moduleId);
    this.menuId = String(menuId);
    this.functionId = String(functionId);
    this.itemType = parseInt(itemType);
    this.create = actionCreate;
    this.read = actionRead;
    this.update = actionUpdate;
    this.delete = actionDelete;
  }
}
class ACTUAL_PERMISSION_ITEM_DELETE {
  constructor(
    token = "",
    permissionItemId = "",
    itemName = "",
    permissionGroupId = "",
    moduleId = "",
    menuId = "",
    functionId = "",
    itemType = 0,
    actionCreate = false,
    actionRead = false,
    actionUpdate = false,
    actionDelete = false
  ) {
    this.token = String(token);
    this.permissionItemId = String(permissionItemId);
    this.itemName = String(itemName);
    this.permissionGroupId = String(permissionGroupId);
    this.moduleId = String(moduleId);
    this.menuId = String(menuId);
    this.functionId = String(functionId);
    this.itemType = parseInt(itemType);
    this.create = actionCreate;
    this.read = actionRead;
    this.update = actionUpdate;
    this.delete = actionDelete;
  }
}

// PERMISSION TEMPLATE
class PERMISSION_TEMPLATE_ALL {
  constructor(token = "") {
    this.token = String(token);
  }
}
class PERMISSION_TEMPLATE_CREATE {
  constructor(token = "", permissionGroupId = "", groupName = "") {
    this.token = String(token);
    this.permissionGroupId = String(permissionGroupId);
    this.groupName = String(groupName);
  }
}
class PERMISSION_TEMPLATE_READ {
  constructor(token = "") {
    this.token = String(token);
  }
}
class PERMISSION_TEMPLATE_UPDATE {
  constructor(token = "", permissionGroupId = "", groupName = "") {
    this.token = String(token);
    this.permissionGroupId = String(permissionGroupId);
    this.groupName = String(groupName);
  }
}
class PERMISSION_TEMPLATE_DELETE {
  constructor(token = "", permissionGroupId = "", groupName = "") {
    this.token = String(token);
    this.permissionGroupId = String(permissionGroupId);
    this.groupName = String(groupName);
  }
}

// PERMISSION ITEM
class PERMISSION_ITEM_ALL {
  constructor(token = "") {
    this.token = String(token);
  }
}
class PERMISSION_ITEM_CREATE {
  constructor(
    token = "",
    permissionItemId = "",
    itemName = "",
    permissionGroupId = "",
    moduleId = "",
    menuId = "",
    functionId = "",
    itemType = 0,
    actionCreate = false,
    actionRead = false,
    actionUpdate = false,
    actionDelete = false
  ) {
    this.token = String(token);
    this.permissionItemId = String(permissionItemId);
    this.itemName = String(itemName);
    this.permissionGroupId = String(permissionGroupId);
    this.moduleId = String(moduleId);
    this.menuId = String(menuId);
    this.functionId = String(functionId);
    this.itemType = parseInt(itemType);
    this.create = actionCreate;
    this.read = actionRead;
    this.update = actionUpdate;
    this.delete = actionDelete;
  }
}
class PERMISSION_ITEM_READ {
  constructor(token = "") {
    this.token = String(token);
  }
}
class PERMISSION_ITEM_UPDATE {
  constructor(
    token = "",
    permissionItemId = "",
    itemName = "",
    permissionGroupId = "",
    moduleId = "",
    menuId = "",
    functionId = "",
    itemType = 0,
    actionCreate = false,
    actionRead = false,
    actionUpdate = false,
    actionDelete = false
  ) {
    this.token = String(token);
    this.permissionItemId = String(permissionItemId);
    this.itemName = String(itemName);
    this.permissionGroupId = String(permissionGroupId);
    this.moduleId = String(moduleId);
    this.menuId = String(menuId);
    this.functionId = String(functionId);
    this.itemType = parseInt(itemType);
    this.create = actionCreate;
    this.read = actionRead;
    this.update = actionUpdate;
    this.delete = actionDelete;
  }
}
class PERMISSION_ITEM_DELETE {
  constructor(
    token = "",
    permissionItemId = "",
    itemName = "",
    permissionGroupId = "",
    moduleId = "",
    menuId = "",
    functionId = "",
    itemType = 0,
    actionCreate = false,
    actionRead = false,
    actionUpdate = false,
    actionDelete = false
  ) {
    this.token = String(token);
    this.permissionItemId = String(permissionItemId);
    this.itemName = String(itemName);
    this.permissionGroupId = String(permissionGroupId);
    this.moduleId = String(moduleId);
    this.menuId = String(menuId);
    this.functionId = String(functionId);
    this.itemType = parseInt(itemType);
    this.create = actionCreate;
    this.read = actionRead;
    this.update = actionUpdate;
    this.delete = actionDelete;
  }
}

// SYS_MODULE
class SYS_MODULE_ALL {
  constructor(token = "") {
    this.token = String(token);
  }
}
class SYS_MODULE_CREATE {
  constructor(
    token = "",
    id = "",
    twName = "",
    enName = "",
    shortCode = "",
    hierarchy = "",
    uri = ""
  ) {
    this.token = String(token);
    this.id = String(id);
    this.twName = String(twName);
    this.enName = String(enName);
    this.shortCode = String(shortCode);
    this.hierarchy = String(hierarchy);
    this.uri = String(uri);
  }
}
class SYS_MODULE_READ {
  constructor(token = "") {
    this.token = String(token);
  }
}
class SYS_MODULE_UPDATE {
  constructor(
    token = "",
    id = "",
    twName = "",
    enName = "",
    shortCode = "",
    hierarchy = "",
    uri = ""
  ) {
    this.token = String(token);
    this.id = String(id);
    this.twName = String(twName);
    this.enName = String(enName);
    this.shortCode = String(shortCode);
    this.hierarchy = String(hierarchy);
    this.uri = String(uri);
  }
}
class SYS_MODULE_DELETE {
  constructor(
    token = "",
    id = "",
    twName = "",
    enName = "",
    shortCode = "",
    hierarchy = "",
    uri = ""
  ) {
    this.token = String(token);
    this.id = String(id);
    this.twName = String(twName);
    this.enName = String(enName);
    this.shortCode = String(shortCode);
    this.hierarchy = String(hierarchy);
    this.uri = String(uri);
  }
}

// SYS_MENU
class SYS_MENU_ALL {
  constructor(token = "") {
    this.token = String(token);
  }
}
class SYS_MENU_CREATE {
  constructor(
    token = "",
    id = "",
    twName = "",
    enName = "",
    shortCode = "",
    hierarchy = "",
    uri = "",
    sysModuleId = ""
  ) {
    this.token = String(token);
    this.id = String(id);
    this.twName = String(twName);
    this.enName = String(enName);
    this.shortCode = String(shortCode);
    this.hierarchy = String(hierarchy);
    this.uri = String(uri);
    this.sysModuleId = String(sysModuleId);
  }
}
class SYS_MENU_READ {
  constructor(token = "") {
    this.token = String(token);
  }
}
class SYS_MENU_UPDATE {
  constructor(
    token = "",
    id = "",
    twName = "",
    enName = "",
    shortCode = "",
    hierarchy = "",
    uri = "",
    sysModuleId = ""
  ) {
    this.token = String(token);
    this.id = String(id);
    this.twName = String(twName);
    this.enName = String(enName);
    this.shortCode = String(shortCode);
    this.hierarchy = String(hierarchy);
    this.uri = String(uri);
    this.sysModuleId = String(sysModuleId);
  }
}
class SYS_MENU_DELETE {
  constructor(
    token = "",
    id = "",
    twName = "",
    enName = "",
    shortCode = "",
    hierarchy = "",
    uri = "",
    sysModuleId = ""
  ) {
    this.token = String(token);
    this.id = String(id);
    this.twName = String(twName);
    this.enName = String(enName);
    this.shortCode = String(shortCode);
    this.hierarchy = String(hierarchy);
    this.uri = String(uri);
    this.sysModuleId = String(sysModuleId);
  }
}

// SYS_FUNCTION
class SYS_FUNCTION_ALL {
  constructor(token = "") {
    this.token = String(token);
  }
}
class SYS_FUNCTION_CREATE {
  constructor(
    token = "",
    id = "",
    twName = "",
    enName = "",
    shortCode = "",
    hierarchy = "",
    uri = "",
    sysMenuId = "",
    actionCreate = false,
    actionRead = false,
    actionUpdate = false,
    actionDelete = false
  ) {
    this.token = String(token);
    this.id = String(id);
    this.twName = String(twName);
    this.enName = String(enName);
    this.shortCode = String(shortCode);
    this.hierarchy = String(hierarchy);
    this.uri = String(uri);
    this.sysMenuId = String(sysMenuId);
    this.create = actionCreate;
    this.read = actionRead;
    this.update = actionUpdate;
    this.delete = actionDelete;
  }
}
class SYS_FUNCTION_READ {
  constructor(token = "") {
    this.token = String(token);
  }
}
class SYS_FUNCTION_UPDATE {
  constructor(
    token = "",
    id = "",
    twName = "",
    enName = "",
    shortCode = "",
    hierarchy = "",
    uri = "",
    sysMenuId = "",
    actionCreate = false,
    actionRead = false,
    actionUpdate = false,
    actionDelete = false
  ) {
    this.token = String(token);
    this.id = String(id);
    this.twName = String(twName);
    this.enName = String(enName);
    this.shortCode = String(shortCode);
    this.hierarchy = String(hierarchy);
    this.uri = String(uri);
    this.sysMenuId = String(sysMenuId);
    this.create = actionCreate;
    this.read = actionRead;
    this.update = actionUpdate;
    this.delete = actionDelete;
  }
}
class SYS_FUNCTION_DELETE {
  constructor(
    token = "",
    id = "",
    twName = "",
    enName = "",
    shortCode = "",
    hierarchy = "",
    uri = "",
    sysMenuId = "",
    actionCreate = false,
    actionRead = false,
    actionUpdate = false,
    actionDelete = false
  ) {
    this.token = String(token);
    this.id = String(id);
    this.twName = String(twName);
    this.enName = String(enName);
    this.shortCode = String(shortCode);
    this.hierarchy = String(hierarchy);
    this.uri = String(uri);
    this.sysMenuId = String(sysMenuId);
    this.create = actionCreate;
    this.read = actionRead;
    this.update = actionUpdate;
    this.delete = actionDelete;
  }
}

export default {
  ALL_PERMISSION_ITEMS_BY_ACCOUNT_ID,
  ACTUAL_GROUP_AND_RELATED_ACCOUNT_ALL,
  ACTUAL_GROUP_AND_RELATED_ACCOUNT_CREATE,
  ACTUAL_GROUP_AND_RELATED_ACCOUNT_DELETE,
  ACTUAL_GROUP_ALL,
  ACTUAL_GROUP_CREATE,
  ACTUAL_GROUP_READ,
  ACTUAL_GROUP_UPDATE,
  ACTUAL_GROUP_DELETE,
  ACTUAL_PERMISSION_ITEM_ALL,
  ACTUAL_PERMISSION_ITEM_CREATE,
  ACTUAL_PERMISSION_ITEM_READ,
  ACTUAL_PERMISSION_ITEM_UPDATE,
  ACTUAL_PERMISSION_ITEM_DELETE,
  PERMISSION_TEMPLATE_ALL,
  PERMISSION_TEMPLATE_CREATE,
  PERMISSION_TEMPLATE_READ,
  PERMISSION_TEMPLATE_UPDATE,
  PERMISSION_TEMPLATE_DELETE,
  PERMISSION_ITEM_ALL,
  PERMISSION_ITEM_CREATE,
  PERMISSION_ITEM_READ,
  PERMISSION_ITEM_UPDATE,
  PERMISSION_ITEM_DELETE,
  SYS_MODULE_ALL,
  SYS_MODULE_CREATE,
  SYS_MODULE_READ,
  SYS_MODULE_UPDATE,
  SYS_MODULE_DELETE,
  SYS_MENU_ALL,
  SYS_MENU_CREATE,
  SYS_MENU_READ,
  SYS_MENU_UPDATE,
  SYS_MENU_DELETE,
  SYS_FUNCTION_ALL,
  SYS_FUNCTION_CREATE,
  SYS_FUNCTION_READ,
  SYS_FUNCTION_UPDATE,
  SYS_FUNCTION_DELETE,
};
