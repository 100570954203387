<template>
  <BContainer fluid class="plain-layout">
    <router-view />
    <BRow class="mt-3">
      <BCol>
        <!-- <router-link v-if="isLoginPage" :to="{ name: 'Register' }">
          註冊
        </router-link>
        <router-link v-else :to="{ name: 'Login' }">登入</router-link> -->
      </BCol>
    </BRow>
  </BContainer>
</template>

<script>
export default {
  name: "PlainLayout",
  computed: {
    isLoginPage() {
      return this.$route.name === "Login";
    },
  },
};
</script>

<style scoped>
.plain-layout {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
