import store from "@/store";
import axios from "axios";
import {
  headers,
  postRequestWithRetry,
  postCRUDRequest,
  replaceUrl,
} from "@/api/apiServices/commonService";
import { API_ROUTES } from "@/api/apiConfig";
import REQUEST_MODELS from "@/api/requestModels/HumanResourceManagement/personnel";

const UserIds = {
  async getByUserID() {
    const token = store.state.auth.token;
    const systemAccountId = store.state.auth.userID;
    const reqModel = new REQUEST_MODELS.QUERY_USER_IDS(
      token,
      systemAccountId,
      "",
      0
    );
    const url = API_ROUTES.PERSONNEL.QUERY_USER_IDS;
    try {
      const response = await axios.post(url, reqModel, {
        headers: headers,
      });
      return response.data;
    } catch (error) {
      console.error("Error in UserIds: ", error);
    }
  },
};

const VerificationCode = {
  async generateVerificationCodeByStaffID(requestData, requestPermissionID) {
    const reqModel = new REQUEST_MODELS.GENERATE_VERIFICATION_CODE_BY_STAFFID(
      requestData.token || "",
      requestData.staffId || ""
    );
    let url = API_ROUTES.PERSONNEL.GENERATE_VERIFICATION_CODE_BY_STAFFID;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    try {
      const response = await postRequestWithRetry(url, reqModel);
      return response;
    } catch (error) {
      console.error("Error in generateVerificationCodeByStaffID: ", error);
    }
  },
};

const StaffRecord = {
  async getAll(requestPermissionID) {
    const token = store.state.auth.token;
    const reqModel = new REQUEST_MODELS.STAFF_RECORD_ALL(token);
    let url = API_ROUTES.PERSONNEL.STAFF_RECORD.ALL;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    try {
      const response = await postRequestWithRetry(url, reqModel);
      return response;
    } catch (error) {
      console.error("Error in StaffRecord: ", error);
    }
  },
  async create(requestData, requestPermissionID) {
    const reqModel = new REQUEST_MODELS.STAFF_RECORD_CREATE(
      requestData.token || "",
      requestData.staffId || "",
      requestData.cnName || "",
      requestData.enName || "",
      requestData.sex || 0,
      requestData.birthDate || "1000-01-01",
      requestData.citizenId || "",
      requestData.marriageStatus || "",
      requestData.telephone || "",
      requestData.cellphone || "",
      requestData.officialTelephone || "",
      requestData.officialCellphone || "",
      requestData.permanentAddr || "",
      requestData.contactAddr || "",
      requestData.emailAddr || "",
      requestData.lastEducationalInstitute || "",
      requestData.departmentByFaculty || "",
      requestData.army || 0,
      requestData.jobStatus || 0,
      requestData.jobTitleId || 0,
      requestData.officialDepartmentId || "",
      requestData.lineId || "",
      requestData.lineToken || "",
      requestData.verificationCode || "",
      requestData.implicitStaffId || 0,
      requestData.accountId || "",
      requestData.serialNo || 0
    );
    let url = API_ROUTES.PERSONNEL.STAFF_RECORD.CREATE;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    return await postCRUDRequest(url, reqModel);
  },
  async fastCreate(requestData, requestPermissionID) {
    const reqModel = new REQUEST_MODELS.STAFF_RECORD_FAST_CREATE(
      requestData.token || "",
      requestData.staffId || ""
    );
    let url = API_ROUTES.PERSONNEL.STAFF_RECORD.CREATE;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    return await postCRUDRequest(url, reqModel);
  },
  async read(requestData) {
    const reqModel = new REQUEST_MODELS.STAFF_RECORD_READ(
      requestData.token || "",
      requestData.staffId || "",
      requestData.verificationCode || ""
    );
    const url = API_ROUTES.PERSONNEL.STAFF_RECORD.READ;
    return await postCRUDRequest(url, reqModel);
  },
  async update(requestData) {
    const reqModel = new REQUEST_MODELS.STAFF_RECORD_UPDATE(
      requestData.token || "",
      requestData.staffId || "",
      requestData.cnName || "",
      requestData.enName || "",
      requestData.sex || 0,
      requestData.birthDate || "1000-01-01",
      requestData.citizenId || "",
      requestData.marriageStatus || "",
      requestData.telephone || "",
      requestData.cellphone || "",
      requestData.officialTelephone || "",
      requestData.officialCellphone || "",
      requestData.permanentAddr || "",
      requestData.contactAddr || "",
      requestData.emailAddr || "",
      requestData.lastEducationalInstitute || "",
      requestData.departmentByFaculty || "",
      requestData.army || 0,
      requestData.jobStatus || 0,
      requestData.jobTitleId || 0,
      requestData.officialDepartmentId || "",
      requestData.lineId || "",
      requestData.lineToken || "",
      requestData.verificationCode || "",
      requestData.implicitStaffId || 0,
      requestData.accountId || "",
      requestData.serialNo || 0
    );
    // console.log("requestData: ", requestData);
    // console.log("reqModel: ", reqModel);
    const url = API_ROUTES.PERSONNEL.STAFF_RECORD.UPDATE;
    return await postCRUDRequest(url, reqModel);
  },
  async delete(requestData, requestPermissionID) {
    const reqModel = new REQUEST_MODELS.STAFF_RECORD_DELETE(
      requestData.token,
      requestData.staffId
    );
    let url = API_ROUTES.PERSONNEL.STAFF_RECORD.DELETE;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    return await postCRUDRequest(url, reqModel);
  },
};

const StaffAndRelatedDepartment = {
  async getAll(requestPermissionID) {
    const token = store.state.auth.token;
    const reqModel = new REQUEST_MODELS.STAFF_AND_RELATED_DEPARTMENT_ALL(token);
    let url = API_ROUTES.PERSONNEL.STAFF_AND_RELATED_DEPARTMENT.ALL;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    try {
      const response = await postRequestWithRetry(url, reqModel);
      return response;
    } catch (error) {
      console.error("Error in StaffAndRelatedDepartment: ", error);
    }
  },
  async create(requestData, requestPermissionID) {
    const reqModel = new REQUEST_MODELS.STAFF_AND_RELATED_DEPARTMENT_CREATE(
      requestData.token || "",
      requestData.serialNo || 0,
      requestData.staffId || "",
      requestData.departmentId || "",
      requestData.priority || 0
    );
    let url = API_ROUTES.PERSONNEL.STAFF_AND_RELATED_DEPARTMENT.CREATE;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    return await postCRUDRequest(url, reqModel);
  },
  async read(requestData) {
    const reqModel = new REQUEST_MODELS.STAFF_AND_RELATED_DEPARTMENT_READ(
      requestData.token || "",
      requestData.serialNo || 0,
      requestData.staffId || "",
      requestData.departmentId || "",
      requestData.priority || 0
    );
    const url = API_ROUTES.PERSONNEL.STAFF_AND_RELATED_DEPARTMENT.READ;
    return await postCRUDRequest(url, reqModel);
  },
  async delete(requestData, requestPermissionID) {
    const reqModel = new REQUEST_MODELS.STAFF_AND_RELATED_DEPARTMENT_READ(
      requestData.token || "",
      requestData.serialNo || 0,
      requestData.staffId || "",
      requestData.departmentId || "",
      requestData.priority || 0
    );
    let url = API_ROUTES.PERSONNEL.STAFF_AND_RELATED_DEPARTMENT.DELETE;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    return await postCRUDRequest(url, reqModel);
  },
};

const JobTitleSetting = {
  async getAll(requestPermissionID) {
    const token = store.state.auth.token;
    const reqModel = new REQUEST_MODELS.JOB_TITLE_SETTING_ALL(token);
    let url = API_ROUTES.PERSONNEL.JOB_TITLE_SETTING.ALL;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    try {
      const response = await postRequestWithRetry(url, reqModel);
      return response;
    } catch (error) {
      console.error("Error in JobTitleSetting getAll: ", error);
    }
  },
  async create(requestData, requestPermissionID) {
    const reqModel = new REQUEST_MODELS.JOB_TITLE_SETTING_CREATE(
      requestData.token || "",
      requestData.serialNo || 0,
      requestData.chName || "",
      requestData.jobLevel || ""
    );
    // console.log("requestData: ", requestData);
    // console.log("reqModel: ", reqModel);
    let url = API_ROUTES.PERSONNEL.JOB_TITLE_SETTING.CREATE;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    return await postCRUDRequest(url, reqModel);
  },
  async read(requestID) {
    const token = store.state.auth.token;
    const reqModel = new REQUEST_MODELS.JOB_TITLE_SETTING_READ(token);
    const url = API_ROUTES.PERSONNEL.JOB_TITLE_SETTING.READ.replace(
      /{([^}]+)}/,
      (match, capturedGroup) => {
        if (capturedGroup === "id") return requestID;
      }
    );
    try {
      const response = await axios.post(url, reqModel, {
        headers: headers,
      });
      return response.data;
    } catch (error) {
      console.error("Error in JobTitleSetting: ", error);
    }
  },
  async update(requestData, requestPermissionID) {
    const reqModel = new REQUEST_MODELS.JOB_TITLE_SETTING_UPDATE(
      requestData.token || "",
      requestData.serialNo || 0,
      requestData.chName || "",
      requestData.jobLevel || ""
    );
    // console.log("requestData: ", requestData);
    // console.log("reqModel: ", reqModel);
    let url = API_ROUTES.PERSONNEL.JOB_TITLE_SETTING.UPDATE;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    return await postCRUDRequest(url, reqModel);
  },
  async delete(requestData, requestPermissionID) {
    const reqModel = new REQUEST_MODELS.JOB_TITLE_SETTING_DELETE(
      requestData.token || "",
      requestData.serialNo || 0,
      requestData.chName || "",
      requestData.jobLevel || ""
    );
    // console.log("requestData: ", requestData);
    // console.log("reqModel: ", reqModel);
    let url = API_ROUTES.PERSONNEL.JOB_TITLE_SETTING.DELETE;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    return await postCRUDRequest(url, reqModel);
  },
};

export default {
  UserIds,
  VerificationCode,
  StaffRecord,
  StaffAndRelatedDepartment,
  JobTitleSetting,
};
