import _ from "lodash";
import API_MODULES from "@/api/apiServices/HumanResourceManagement/personnel";
// import INPUT_OPTIONS from "@/config/inputOptions/HumanResourceManagement";
import COLUMNS from "@/config/columns/HumanResourceManagement";

const state = {
  listOfStaffs: null,
  staffCategories: {
    listOfManagers: null,
    listOfActingManagers: null,
    listOfManagersAndActingManagers: null,
    listOfStoreManagers: null,
    listOfActingStoreManagers: null,
    listOfStoreManagersAndActingStoreManagers: null,
  },
  listOfStaffsAndRelatedDepartments: null,
  listOfJobTitleSettings: null,
};

const getters = {
  /**
   * 根據listOfDepartments的`departmentId`獲取對應完整的department信息
   * （事前已將`levelProperty`從listOfDepartmentLevelSettings合併到listOfDepartments中）。
   * 再合併levelProperty「單位」到listOfStaffsAndRelatedDepartments中的每個人員與部門對應。
   * @returns {Array}
   */
  getAndMergeLevelPropertyFromDepartment:
    () => (listOfStaffsAndRelatedDepartments, listOfDepartments) => {
      const mergedData = _.map(
        listOfStaffsAndRelatedDepartments,
        (department) => {
          const matchedDepartment = _.find(listOfDepartments, {
            departmentId: department["departmentId"],
          });
          if (matchedDepartment) {
            return {
              ...department,
              levelProperty: matchedDepartment["levelProperty"],
            };
          }
          return department;
        }
      );
      return mergedData;
    },
  /**
   * 根據部門序列號過濾員工記錄列表。
   * @returns {Array}
   */
  staffRecordsByDepartment: (state) => (departmentStringID) => {
    const list = _.cloneDeep(state.listOfStaffs);
    return _.filter(list, { officialDepartmentId: departmentStringID });
  },
  /**
   * 根據職稱編號過濾員工記錄列表。
   * @returns {Array}
   */
  staffRecordsByJobTitle: (state) => (jobTitleSN) => {
    const list = _.cloneDeep(state.listOfStaffs);
    return _.filter(list, { jobTitleId: jobTitleSN });
  },
};

const mutations = {
  SET_LIST_OF_STAFF_RECORDS(state, list) {
    state.listOfStaffs = list;
  },
  /**
   * 根據職位名稱過濾員工記錄，將符合條件的記錄存儲到不同的 list 當中
   * @param {Object} INPUT_OPTIONS - 輸入規則對象，包含員工記錄的職位規則
   * @param {string} jobTitleName - 職位名稱，用於過濾員工記錄
   * @param {Array} state.listOfStaffs - 員工記錄列表
   */
  SET_LIST_OF_SPECIFIC_GROUPS(state) {
    /**
     * 根據職位名稱過濾員工記錄的內部函數。
     * @param {Array} records - 要過濾的員工記錄列表
     * @param {string} jobTitleName - 職位名稱，用於過濾員工記錄
     * @returns {Array} - 符合條件的員工記錄列表
     */
    function filterStaffRecordsByJobTitle(staffs, jobTitle) {
      const jobTitles = _.cloneDeep(state.listOfJobTitleSettings);
      const jobTitleInfo = _.find(jobTitles, { chName: jobTitle });
      const valueField = COLUMNS.jobTitleId.data.options.valueField;
      return _.filter(staffs, { jobTitleId: jobTitleInfo[valueField] });
    }
    const staffs = _.cloneDeep(state.listOfStaffs);
    state.staffCategories.listOfManagers = filterStaffRecordsByJobTitle(
      staffs,
      "主任"
    );
    state.staffCategories.listOfActingManagers = filterStaffRecordsByJobTitle(
      staffs,
      "代理主任"
    );
    state.staffCategories.listOfManagersAndActingManagers = [
      ...state.staffCategories.listOfManagers,
      ...state.staffCategories.listOfActingManagers,
    ];
    state.staffCategories.listOfStoreManagers = filterStaffRecordsByJobTitle(
      staffs,
      "店長"
    );
    state.staffCategories.listOfActingStoreManagers =
      filterStaffRecordsByJobTitle(staffs, "代理店長");
    state.staffCategories.listOfStoreManagersAndActingStoreManagers = [
      ...state.staffCategories.listOfStoreManagers,
      ...state.staffCategories.listOfActingStoreManagers,
    ];
  },
  SET_LIST_OF_STAFFS_AND_RELATED_DEPARTMENTS(state, list) {
    state.listOfStaffsAndRelatedDepartments = list;
  },
  SET_LIST_OF_JOB_TITLE_SETTINGS(state, list) {
    state.listOfJobTitleSettings = list;
  },
};

const actions = {
  /**
   * 在完成「新增」、「修改」、「刪除」操作以後，需立即請求並且更新相關資料。
   * 最後重新獲取當前賬號的所有權限資料，即時更新畫面資料（不需要額外刷新頁面）。
   * @returns {Promise<void>}
   */
  async refreshDataByAction({ dispatch }, action) {
    await dispatch(action);
    await dispatch(
      "SystemManagement/getAllDetailsOfPermissionItemsByAccountID",
      null,
      { root: true }
    );
  },
  /**
   * 根據員工編號生成驗證碼
   * @param {string} data.staffID - 員工編號
   */
  async generateVerificationCodeByStaffID({ rootState, dispatch }, data) {
    // 設爲固定，無需每次代入（111_ 開頭皆爲人事權限）
    const permissionID = "111_1_EIMU";
    const requestData = {
      ...data,
      token: rootState.auth.token,
    };
    const responseData =
      await API_MODULES.VerificationCode.generateVerificationCodeByStaffID(
        requestData,
        permissionID
      );
    await dispatch("refreshDataByAction", "getAllStaffRecords");
    return responseData;
  },
  // STAFF RECORD
  async getAllStaffRecords({ rootState, commit, rootGetters, dispatch }) {
    const permissionID = rootGetters.getPermissionID(
      rootState.SystemManagement.listOfPermissionIDs
    );
    let responseData = await API_MODULES.StaffRecord.getAll(permissionID);
    responseData = responseData.map((data) => {
      data["birthDate"] = data["birthDate"].split("T")[0];
      return data;
    });
    await dispatch("getAllJobTitleSettings");
    await commit("SET_LIST_OF_STAFF_RECORDS", _.defaultTo(responseData, []));
    await commit("SET_LIST_OF_SPECIFIC_GROUPS");
  },
  async createStaffRecord({ rootState, rootGetters, dispatch }, data) {
    const permissionID = rootGetters.getPermissionID(
      rootState.SystemManagement.listOfPermissionIDs
    );
    const requestData = { ...data, token: rootState.auth.token };
    const responseData = await API_MODULES.StaffRecord.create(
      requestData,
      permissionID
    );
    await dispatch("refreshDataByAction", "getAllStaffRecords");
    return responseData;
  },
  /**
   * 快速創建一個新的員工記錄
   * @param {Object} data.staffID - 只需要員工編號
   */
  async fastCreateStaffRecord({ rootState, rootGetters, dispatch }, data) {
    const permissionID = rootGetters.getPermissionID(
      rootState.SystemManagement.listOfPermissionIDs
    );
    const requestData = { ...data, token: rootState.auth.token };
    const responseData = await API_MODULES.StaffRecord.fastCreate(
      requestData,
      permissionID
    );
    await dispatch("refreshDataByAction", "getAllStaffRecords");
    return responseData;
  },
  /**
   * 讀取相關員工記錄（非權限擁有者需要完成認證碼驗證才能成功讀取該資料）
   * @param {Object} data.staffID - 員工編號
   * @param {Object} data.verificationCode - 驗證碼
   */
  async readStaffRecord({ rootState }, data) {
    const requestData = { ...data, token: rootState.auth.token };
    let responseData = await API_MODULES.StaffRecord.read(requestData);
    responseData = {
      ..._.cloneDeep(responseData),
      birthDate: responseData["birthDate"].split("T")[0],
    };
    return responseData;
  },
  /**
   * 修改相關員工記錄（非權限擁有者需要完成認證碼驗證才能成功修改該資料）
   * @param {Object} data.staffID - 員工編號
   * @param {Object} data.verificationCode - 驗證碼
   */
  async updateStaffRecord({ rootState, dispatch }, data) {
    const requestData = { ...data, token: rootState.auth.token };
    const responseData = await API_MODULES.StaffRecord.update(requestData);
    await dispatch("refreshDataByAction", "getAllStaffRecords");
    return responseData;
  },
  async deleteStaffRecord({ rootState, rootGetters, dispatch }, data) {
    const permissionID = rootGetters.getPermissionID(
      rootState.SystemManagement.listOfPermissionIDs
    );
    const requestData = { ...data, token: rootState.auth.token };
    const responseData = await API_MODULES.StaffRecord.delete(
      requestData,
      permissionID
    );
    await dispatch("refreshDataByAction", "getAllStaffRecords");
    return responseData;
  },
  // STAFF AND RELATED DEPARTMENT
  async getAllStaffsAndRelatedDepartments({
    rootState,
    rootGetters,
    getters,
    commit,
  }) {
    const permissionID = rootGetters.getPermissionID(
      rootState.SystemManagement.listOfPermissionIDs
    );
    let responseData = await API_MODULES.StaffAndRelatedDepartment.getAll(
      permissionID
    );
    responseData = _.sortBy(responseData, ["staffId", "priority"]);
    const mergedData = getters.getAndMergeLevelPropertyFromDepartment(
      responseData,
      rootState.SystemManagement.listOfDepartments
    );
    commit(
      "SET_LIST_OF_STAFFS_AND_RELATED_DEPARTMENTS",
      _.defaultTo(mergedData, [])
    );
  },
  async createStaffAndRelatedDepartment(
    { rootState, rootGetters, dispatch },
    data
  ) {
    const permissionID = rootGetters.getPermissionID(
      rootState.SystemManagement.listOfPermissionIDs
    );
    const requestData = { ...data, token: rootState.auth.token };
    const responseData = await API_MODULES.StaffAndRelatedDepartment.create(
      requestData,
      permissionID
    );
    await dispatch("refreshDataByAction", "getAllStaffsAndRelatedDepartments");
    return responseData;
  },
  async deleteStaffAndRelatedDepartment(
    { rootState, rootGetters, dispatch },
    data
  ) {
    const permissionID = rootGetters.getPermissionID(
      rootState.SystemManagement.listOfPermissionIDs
    );
    const requestData = { ...data, token: rootState.auth.token };
    const responseData = await API_MODULES.StaffAndRelatedDepartment.delete(
      requestData,
      permissionID
    );
    await dispatch("refreshDataByAction", "getAllStaffsAndRelatedDepartments");
    return responseData;
  },
  // JOB TITLE SETTING
  async getAllJobTitleSettings({ rootState, rootGetters, commit }) {
    const permissionID = rootGetters.getPermissionID(
      rootState.SystemManagement.listOfPermissionIDs
    );
    const responseData = await API_MODULES.JobTitleSetting.getAll(permissionID);
    commit("SET_LIST_OF_JOB_TITLE_SETTINGS", _.defaultTo(responseData, []));
  },
  async createJobTitleSetting({ rootState, rootGetters, dispatch }, data) {
    const permissionID = rootGetters.getPermissionID(
      rootState.SystemManagement.listOfPermissionIDs
    );
    const requestData = { ...data, token: rootState.auth.token };
    const responseData = await API_MODULES.JobTitleSetting.create(
      requestData,
      permissionID
    );
    await dispatch("refreshDataByAction", "getAllJobTitleSettings");
    return responseData;
  },
  async updateJobTitleSetting({ rootState, rootGetters, dispatch }, data) {
    const permissionID = rootGetters.getPermissionID(
      rootState.SystemManagement.listOfPermissionIDs
    );
    const requestData = { ...data, token: rootState.auth.token };
    const responseData = await API_MODULES.JobTitleSetting.update(
      requestData,
      permissionID
    );
    await dispatch("refreshDataByAction", "getAllJobTitleSettings");
    return responseData;
  },
  async deleteJobTitleSetting({ rootState, rootGetters, dispatch }, data) {
    const permissionID = rootGetters.getPermissionID(
      rootState.SystemManagement.listOfPermissionIDs
    );
    const requestData = { ...data, token: rootState.auth.token };
    const responseData = await API_MODULES.JobTitleSetting.delete(
      requestData,
      permissionID
    );
    await dispatch("refreshDataByAction", "getAllJobTitleSettings");
    return responseData;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
