// import SystemManagementLayout from "@/layouts/SystemManagementLayout.vue";
// import OrganizationManagementLayout from "@/layouts/OrganizationManagementLayout.vue";
// import OrganizationAndEmployeeManagementLayout from "@/layouts/OrganizationAndEmployeeManagementLayout.vue";

// 系統配置管理 System Configuration Management
const SM10_101_Routes = [
  {
    path: "system-configuration-management",
    name: "System-Configuration-Management",
    // component: SystemManagementLayout, // TODO: change
    meta: {
      title: `系統配置管理 - ${process.env.VUE_APP_COMPANY_NAME}`,
    },
  },
  {
    //  模组維護 101_1
    path: "101_1",
    name: "Module-Maintenance",
    component: () =>
      import(
        "@/views/SystemManagement/101-SystemConfigurationManagement/101_1/SM_101_1_ModuleView.vue"
      ),
    meta: {
      title: `模組維護 - ${process.env.VUE_APP_COMPANY_NAME}`,
    },
  },
  {
    // 選單與功能維護 101_2
    path: "101_2",
    name: "Menu-Maintenance",
    component: () =>
      import(
        "@/views/SystemManagement/101-SystemConfigurationManagement/101_2/SM_101_2_MenuView.vue"
      ),
    meta: {
      title: `選單與功能維護 - ${process.env.VUE_APP_COMPANY_NAME}`,
    },
  },
];

// 功能權限管理 Functionality Permission Management
const SM10_102_Routes = [
  {
    path: "functionality-permission-management",
    name: "Functionality-Permission-Management",
    // component: SystemManagementLayout, // TODO: change
    meta: {
      title: `功能權限管理 - ${process.env.VUE_APP_COMPANY_NAME}`,
    },
  },
  {
    // 範本維護 102_1
    path: "102_1",
    name: "Permission-Template-Group-Maintenance",
    component: () =>
      import(
        "@/views/SystemManagement/102-FunctionalityPermissionManagement/102_1/SM_102_1_TemplateGroupView.vue"
      ),
    meta: {
      title: `範本維護 - ${process.env.VUE_APP_COMPANY_NAME}`,
    },
  },
  {
    // 範本權限維護 102_2
    path: "102_2",
    name: "Permission-Template-Item-Maintenance",
    component: () =>
      import(
        "@/views/SystemManagement/102-FunctionalityPermissionManagement/102_2/SM_102_2_TemplateItemView.vue"
      ),
    meta: {
      title: `範本權限維護 - ${process.env.VUE_APP_COMPANY_NAME}`,
    },
  },
  {
    // 群組維護 102_3
    path: "102_3",
    name: "Actual-Group-Configuration",
    component: () =>
      import(
        "@/views/SystemManagement/102-FunctionalityPermissionManagement/102_3/SM_102_3_ActualGroupView.vue"
      ),
    meta: {
      title: `群組維護 - ${process.env.VUE_APP_COMPANY_NAME}`,
    },
  },
  {
    // 群組權限與人員維護 102_4
    path: "102_4",
    name: "Permission-Actual-Item-And-Member-Maintenance",
    component: () =>
      import(
        "@/views/SystemManagement/102-FunctionalityPermissionManagement/102_4/SM_102_4_ActualItemView.vue"
      ),
    meta: {
      title: `群組權限與人員維護 - ${process.env.VUE_APP_COMPANY_NAME}`,
    },
  },
];

// 組織管理 Organization Management
const SM10_103_Routes = [
  {
    path: "organization-management",
    name: "Organization-Management",
    // component: OrganizationManagementLayout,
    meta: {
      title: `組織管理 - ${process.env.VUE_APP_COMPANY_NAME}`,
    },
  },
  {
    // 員工與部門及門店設定 103_1
    path: "103_1",
    name: "Employee-And-Department-Configuration",
    // component: () =>
    //   import(
    //     "@/views/SystemManagement/104-OrganizationManagement/View.vue"
    //   ),
    meta: {
      title: `員工與部門及門店設定 - ${process.env.VUE_APP_COMPANY_NAME}`,
    },
  },
  {
    // 總公司部門設定 103_2
    path: "103_2",
    name: "Department-Configuration",
    component: () =>
      import(
        "@/views/SystemManagement/103-OrganizationManagement/103_2/SM_103_2_OrganizationDepartmentView.vue"
      ),
    meta: {
      title: `總公司部門設定 - ${process.env.VUE_APP_COMPANY_NAME}`,
    },
  },
  {
    // 部門層級設定 103_3
    path: "103_3",
    name: "Organization-Department-Level-Configuration",
    component: () =>
      import(
        "@/views/SystemManagement/103-OrganizationManagement/103_3/SM_103_3_OrganizationDepartmentLevelView.vue"
      ),
    meta: {
      title: `部門層級設定 - ${process.env.VUE_APP_COMPANY_NAME}`,
    },
  },
  {
    // 職稱設定 103_4
    path: "103_4",
    name: "Job-Title-Configuration",
    component: () =>
      import(
        "@/views/SystemManagement/103-OrganizationManagement/103_4/SM_103_4_JobTitleView.vue"
      ),
    meta: {
      title: `職稱設定 - ${process.env.VUE_APP_COMPANY_NAME}`,
    },
  },
];

// 企業資料管理 Corporation Information Management
const SM10_104_Routes = [
  {
    path: "corporation-information-management",
    name: "Corporation-Information-Management",
    meta: {
      title: `企業資料管理 - ${process.env.VUE_APP_COMPANY_NAME}`,
    },
  },
  {
    // 集團與品牌維護 104_1
    path: "104_1",
    name: "Corporation-And-Brand-Information-Maintenance",
    component: () =>
      import(
        "@/views/SystemManagement/104-CorporationInformationManagement/104_1/SM_104_1_CorporationBrandView.vue"
      ),
    meta: {
      title: `集團與品牌維護 - ${process.env.VUE_APP_COMPANY_NAME}`,
    },
  },
  {
    // 公司維護 104_2
    path: "104_2",
    name: "Company-Maintenance",
    component: () =>
      import(
        "@/views/SystemManagement/104-CorporationInformationManagement/104_2/SM_104_2_CompanyView.vue"
      ),
    meta: {
      title: `公司維護 - ${process.env.VUE_APP_COMPANY_NAME}`,
    },
  },
  {
    // 子公司維護 104_3
    path: "104_3",
    name: "Subsidiary-Company-Maintenance",
    component: () =>
      import(
        "@/views/SystemManagement/104-CorporationInformationManagement/104_3/SM_104_3_SubsidiaryView.vue"
      ),
    meta: {
      title: `子公司維護 - ${process.env.VUE_APP_COMPANY_NAME}`,
    },
  },
  {
    // 門店維護 104_4
    path: "104_4",
    name: "Store-Maintenance",
    component: () =>
      import(
        "@/views/SystemManagement/104-CorporationInformationManagement/104_4/SM_104_4_StoreView.vue"
      ),
    meta: {
      title: `門店維護 - ${process.env.VUE_APP_COMPANY_NAME}`,
    },
  },
];

export default {
  SM10_101_Routes,
  SM10_102_Routes,
  SM10_103_Routes,
  SM10_104_Routes,
};
