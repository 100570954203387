/**
 * 模組代碼與路由對應表
 */
const moduleShortCodeRouterPair = {
  SM10: "System-Management", // 系統管理
  HR11: "Human-Resource-Management", // 人資管理
  PM12: "Product-Management", // 商品管理
};

/**
 * 選單代碼與路由對應表
 */
const menuShortCodeRouterPair = {
  // 系統管理 SM10
  SM10_101: "System-Configuration-Management", // 系統配置管理
  SM10_102: "Functionality-Permission-Management", // 功能權限管理
  SM10_103: "Organization-Management", // 組織管理
  SM10_104: "Corporation-Information-Management", // 企業資料管理
  // 人資系統 HR11
  HR11_111: "Employee-Information-Management", // 員工資料管理
  HR11_113: "Employee-And-Organization-Management", // 員工與組織管理
  // 商品管理 PM12
  PM12_121: "Product-Definition-Management", // 商品定義管理
  PM12_122: "Product-Category-Management", // 商品分類管理
  PM12_127: "Vendor-Management", // 合作廠商管理
  PM12_128: "Measurement-Unit-Management", // 計量單位管理
};

/**
 * 功能代碼與路由對應表
 */
const functionShortCodeRouterPair = {
  // 系統配置管理 SM10-101
  "101_1": "Module-Maintenance",
  "101_2": "Menu-Maintenance",
  "101_3": "Function-Maintenance",
  // 功能權限管理 SM10-102
  "102_1": "Permission-Template-Group-Maintenance",
  "102_2": "Permission-Template-Item-Maintenance",
  "102_3": "Actual-Group-Configuration",
  "102_4": "Permission-Actual-Item-And-Member-Maintenance",
  // 組織管理 SM10-103
  "103_1": "Employee-And-Department-Configuration",
  "103_2": "Department-Configuration",
  "103_3": "Organization-Department-Level-Configuration",
  "103_4": "Job-Title-Configuration",
  // 企業資料管理 SM10-104
  "104_1": "Corporation-And-Brand-Information-Maintenance",
  "104_2": "Company-Maintenance",
  "104_3": "Subsidiary-Company-Maintenance",
  "104_4": "Store-Maintenance",
  // 員工資料管理 HR11-111
  "111_1": "Employee-Information-Maintenance", // 員工資料維護
  "111_2": "New-Employee-Creation", // 員工資料新增
  "111_3": "Employee-Information-Entry", // 員工資料填寫
  // 員工與組織管理 HR11-113
  "113_1": "Employee-And-Department-Maintenance", // 員工與部門維護
  // 商品定義管理 PM12-121
  "121_1": "Product-Attribute-Type-Overview-Maintenance",
  "121_2": "Product-Attribute-Value-Overview-Maintenance",
  "121_3": "Product-Post-Processsing-Type-Overview-Maintenance",
  "121_4": "Product-Post-Processsing-Value-Overview-Maintenance",
  // 商品分類管理 PM12-122
  "122_1": "Product-Category-Class-Model-Maintenance",
  "122_2": "Product-Configuration-Maintenance",
  "122_3": "Product-Combination-Maintenance",
  "122_10000": "Product-Category-Maintenance",
  "122_20000": "Product-Class-And-Model-Maintenance",
  // 合作廠商管理 PM12-127
  "127_1": "Printing-Vendor-Information-Maintenance",
  // 計量單位管理 PM12-128
  "128_1": "Print-Unit-Maintenance",
};

export default {
  moduleShortCodeRouterPair,
  menuShortCodeRouterPair,
  functionShortCodeRouterPair,
};
