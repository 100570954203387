// CORPORATION
class GROUP_OF_ENTERPRISES_ALL {
  constructor(token = "") {
    this.token = String(token);
  }
}
class GROUP_OF_ENTERPRISES_CREATE {
  constructor(
    token = "",
    serialNo = 0,
    groupName = "",
    groupBossName = "",
    note = ""
  ) {
    this.token = String(token);
    this.serialNo = parseInt(serialNo);
    this.groupName = String(groupName);
    this.groupBossName = String(groupBossName);
    this.note = String(note);
  }
}
class GROUP_OF_ENTERPRISES_READ {
  constructor(token = "") {
    this.token = String(token);
  }
}
class GROUP_OF_ENTERPRISES_UPDATE {
  constructor(
    token = "",
    serialNo = 0,
    groupName = "",
    groupBossName = "",
    note = ""
  ) {
    this.token = String(token);
    this.serialNo = parseInt(serialNo);
    this.groupName = String(groupName);
    this.groupBossName = groupBossName;
    this.note = String(note);
  }
}
class GROUP_OF_ENTERPRISES_DELETE {
  constructor(
    token = "",
    serialNo = 0,
    groupName = "",
    groupBossName = "",
    note = ""
  ) {
    this.token = String(token);
    this.serialNo = parseInt(serialNo);
    this.groupName = String(groupName);
    this.groupBossName = groupBossName;
    this.note = String(note);
  }
}

// BRAND
class BRAND_ALL {
  constructor(token = "") {
    this.token = String(token);
  }
}
class BRAND_CREATE {
  constructor(token = "", serialNo = 0, groupId = 0, chName = "", enName = "") {
    this.token = String(token);
    this.serialNo = parseInt(serialNo);
    this.groupId = parseInt(groupId);
    this.chName = String(chName);
    this.enName = String(enName);
  }
}
class BRAND_READ {
  constructor(token = "") {
    this.token = String(token);
  }
}
class BRAND_UPDATE {
  constructor(token = "", serialNo = 0, groupId = 0, chName = "", enName = "") {
    this.token = String(token);
    this.serialNo = parseInt(serialNo);
    this.groupId = parseInt(groupId);
    this.chName = String(chName);
    this.enName = String(enName);
  }
}
class BRAND_DELETE {
  constructor(token = "", serialNo = 0, groupId = 0, chName = "", enName = "") {
    this.token = String(token);
    this.serialNo = parseInt(serialNo);
    this.groupId = parseInt(groupId);
    this.chName = String(chName);
    this.enName = String(enName);
  }
}

// COMPANY
class COMPANY_ALL {
  constructor(token = "") {
    this.token = String(token);
  }
}
class COMPANY_CREATE {
  constructor(
    token = "",
    serialNo = 0,
    brandId = 0,
    chName = "",
    enName = "",
    upstreamCompany = 0,
    companyType = 0,
    publicRegistrationCode = "",
    representative = "",
    surrogate = "",
    telephone1 = "",
    telephone2 = "",
    fax = "",
    registrationAddress = "",
    contactAddress = "",
    contact = "",
    contactEmail = ""
  ) {
    this.token = String(token);
    this.serialNo = parseInt(serialNo);
    this.brandId = parseInt(brandId);
    this.chName = String(chName);
    this.enName = String(enName);
    this.upstreamCompany = parseInt(upstreamCompany);
    this.companyType = parseInt(companyType);
    this.publicRegistrationCode = String(publicRegistrationCode);
    this.representative = String(representative);
    this.surrogate = String(surrogate);
    this.telephone1 = String(telephone1);
    this.telephone2 = String(telephone2);
    this.fax = String(fax);
    this.registrationAddress = String(registrationAddress);
    this.contactAddress = String(contactAddress);
    this.contact = String(contact);
    this.contactEmail = String(contactEmail);
  }
}
class COMPANY_READ {
  constructor(token = "") {
    this.token = String(token);
  }
}
class COMPANY_UPDATE {
  constructor(
    token = "",
    serialNo = 0,
    brandId = 0,
    chName = "",
    enName = "",
    upstreamCompany = 0,
    companyType = 0,
    publicRegistrationCode = "",
    representative = "",
    surrogate = "",
    telephone1 = "",
    telephone2 = "",
    fax = "",
    registrationAddress = "",
    contactAddress = "",
    contact = "",
    contactEmail = ""
  ) {
    this.token = String(token);
    this.serialNo = parseInt(serialNo);
    this.brandId = parseInt(brandId);
    this.chName = String(chName);
    this.enName = String(enName);
    this.upstreamCompany = parseInt(upstreamCompany);
    this.companyType = parseInt(companyType);
    this.publicRegistrationCode = String(publicRegistrationCode);
    this.representative = String(representative);
    this.surrogate = String(surrogate);
    this.telephone1 = String(telephone1);
    this.telephone2 = String(telephone2);
    this.fax = String(fax);
    this.registrationAddress = String(registrationAddress);
    this.contactAddress = String(contactAddress);
    this.contact = String(contact);
    this.contactEmail = String(contactEmail);
  }
}
class COMPANY_DELETE {
  constructor(
    token = "",
    serialNo = 0,
    brandId = 0,
    chName = "",
    enName = "",
    upstreamCompany = 0,
    companyType = 0,
    publicRegistrationCode = "",
    representative = "",
    surrogate = "",
    telephone1 = "",
    telephone2 = "",
    fax = "",
    registrationAddress = "",
    contactAddress = "",
    contact = "",
    contactEmail = ""
  ) {
    this.token = String(token);
    this.serialNo = parseInt(serialNo);
    this.brandId = parseInt(brandId);
    this.chName = String(chName);
    this.enName = String(enName);
    this.upstreamCompany = parseInt(upstreamCompany);
    this.companyType = parseInt(companyType);
    this.publicRegistrationCode = String(publicRegistrationCode);
    this.representative = String(representative);
    this.surrogate = String(surrogate);
    this.telephone1 = String(telephone1);
    this.telephone2 = String(telephone2);
    this.fax = String(fax);
    this.registrationAddress = String(registrationAddress);
    this.contactAddress = String(contactAddress);
    this.contact = String(contact);
    this.contactEmail = String(contactEmail);
  }
}

// SUBSIDIARY
class SUBSIDIARY_ALL {
  constructor(token = "") {
    this.token = String(token);
  }
}
class SUBSIDIARY_CREATE {
  constructor(
    token = "",
    serialNo = 0,
    companyId = 0,
    chName = "",
    enName = "",
    publicRegistrationCode = "",
    representative = "",
    surrogate = "",
    telephone1 = "",
    telephone2 = "",
    fax = "",
    registrationAddress = "",
    contactAddress = "",
    contact = "",
    contactEmail = ""
  ) {
    this.token = String(token);
    this.serialNo = parseInt(serialNo);
    this.companyId = parseInt(companyId);
    this.chName = String(chName);
    this.enName = String(enName);
    this.publicRegistrationCode = String(publicRegistrationCode);
    this.representative = String(representative);
    this.surrogate = String(surrogate);
    this.telephone1 = String(telephone1);
    this.telephone2 = String(telephone2);
    this.fax = String(fax);
    this.registrationAddress = String(registrationAddress);
    this.contactAddress = String(contactAddress);
    this.contact = String(contact);
    this.contactEmail = String(contactEmail);
  }
}
class SUBSIDIARY_READ {
  constructor(token = "") {
    this.token = String(token);
  }
}
class SUBSIDIARY_UPDATE {
  constructor(
    token = "",
    serialNo = 0,
    companyId = 0,
    chName = "",
    enName = "",
    publicRegistrationCode = "",
    representative = "",
    surrogate = "",
    telephone1 = "",
    telephone2 = "",
    fax = "",
    registrationAddress = "",
    contactAddress = "",
    contact = "",
    contactEmail = ""
  ) {
    this.token = String(token);
    this.serialNo = parseInt(serialNo);
    this.companyId = parseInt(companyId);
    this.chName = String(chName);
    this.enName = String(enName);
    this.publicRegistrationCode = String(publicRegistrationCode);
    this.representative = String(representative);
    this.surrogate = String(surrogate);
    this.telephone1 = String(telephone1);
    this.telephone2 = String(telephone2);
    this.fax = String(fax);
    this.registrationAddress = String(registrationAddress);
    this.contactAddress = String(contactAddress);
    this.contact = String(contact);
    this.contactEmail = String(contactEmail);
  }
}
class SUBSIDIARY_DELETE {
  constructor(
    token = "",
    serialNo = 0,
    companyId = 0,
    chName = "",
    enName = "",
    publicRegistrationCode = "",
    representative = "",
    surrogate = "",
    telephone1 = "",
    telephone2 = "",
    fax = "",
    registrationAddress = "",
    contactAddress = "",
    contact = "",
    contactEmail = ""
  ) {
    this.token = String(token);
    this.serialNo = parseInt(serialNo);
    this.companyId = parseInt(companyId);
    this.chName = String(chName);
    this.enName = String(enName);
    this.publicRegistrationCode = String(publicRegistrationCode);
    this.representative = String(representative);
    this.surrogate = String(surrogate);
    this.telephone1 = String(telephone1);
    this.telephone2 = String(telephone2);
    this.fax = String(fax);
    this.registrationAddress = String(registrationAddress);
    this.contactAddress = String(contactAddress);
    this.contact = String(contact);
    this.contactEmail = String(contactEmail);
  }
}

// DEPARTMENT
class DEPARTMENT_ALL {
  constructor(token = "") {
    this.token = String(token);
  }
}
class DEPARTMENT_CREATE {
  constructor(
    token = "",
    serialNo = 0,
    companyId = 0,
    departmentId = "",
    chName = "",
    enName = "",
    managerId = "",
    levelSerialNo = 0,
    upstreamDepartmentId = "",
    isStore = false
  ) {
    this.token = String(token);
    this.serialNo = parseInt(serialNo);
    this.companyId = parseInt(companyId);
    this.departmentId = String(departmentId);
    this.chName = String(chName);
    this.enName = String(enName);
    this.managerId = String(managerId);
    this.levelSerialNo = parseInt(levelSerialNo);
    this.upstreamDepartmentId = String(upstreamDepartmentId);
    this.isStore = isStore;
  }
}
class DEPARTMENT_READ {
  constructor(token = "") {
    this.token = String(token);
  }
}
class DEPARTMENT_UPDATE {
  constructor(
    token = "",
    serialNo = 0,
    companyId = 0,
    departmentId = "",
    chName = "",
    enName = "",
    managerId = "",
    levelSerialNo = 0,
    upstreamDepartmentId = "",
    isStore = false
  ) {
    this.token = String(token);
    this.serialNo = parseInt(serialNo);
    this.companyId = parseInt(companyId);
    this.departmentId = String(departmentId);
    this.chName = String(chName);
    this.enName = String(enName);
    this.managerId = String(managerId);
    this.levelSerialNo = parseInt(levelSerialNo);
    this.upstreamDepartmentId = String(upstreamDepartmentId);
    this.isStore = isStore;
  }
}
class DEPARTMENT_DELETE {
  constructor(
    token = "",
    serialNo = 0,
    companyId = 0,
    departmentId = "",
    chName = "",
    enName = "",
    managerId = "",
    levelSerialNo = 0,
    upstreamDepartmentId = "",
    isStore = false
  ) {
    this.token = String(token);
    this.serialNo = parseInt(serialNo);
    this.companyId = parseInt(companyId);
    this.departmentId = String(departmentId);
    this.chName = String(chName);
    this.enName = String(enName);
    this.managerId = String(managerId);
    this.levelSerialNo = parseInt(levelSerialNo);
    this.upstreamDepartmentId = String(upstreamDepartmentId);
    this.isStore = isStore;
  }
}

// DEPARTMENT LEVEL SETTING
class DEPARTMENT_LEVEL_SETTING_ALL {
  constructor(token = "") {
    this.token = String(token);
  }
}
class DEPARTMENT_LEVEL_SETTING_CREATE {
  constructor(
    token = "",
    serialNo = 0,
    lv = 0,
    levelName = "",
    upstreamId = 0,
    levelProperty = ""
  ) {
    this.token = String(token);
    this.serialNo = parseInt(serialNo);
    this.lv = parseInt(lv);
    this.levelName = String(levelName);
    this.upstreamId = parseInt(upstreamId);
    this.levelProperty = String(levelProperty);
  }
}
class DEPARTMENT_LEVEL_SETTING_READ {
  constructor(token = "") {
    this.token = String(token);
  }
}
class DEPARTMENT_LEVEL_SETTING_UPDATE {
  constructor(
    token = "",
    serialNo = 0,
    lv = 0,
    levelName = "",
    upstreamId = 0,
    levelProperty = ""
  ) {
    this.token = String(token);
    this.serialNo = parseInt(serialNo);
    this.lv = parseInt(lv);
    this.levelName = String(levelName);
    this.upstreamId = parseInt(upstreamId);
    this.levelProperty = String(levelProperty);
  }
}
class DEPARTMENT_LEVEL_SETTING_DELETE {
  constructor(
    token = "",
    serialNo = 0,
    lv = 0,
    levelName = "",
    upstreamId = 0,
    levelProperty = ""
  ) {
    this.token = String(token);
    this.serialNo = parseInt(serialNo);
    this.lv = parseInt(lv);
    this.levelName = String(levelName);
    this.upstreamId = parseInt(upstreamId);
    this.levelProperty = String(levelProperty);
  }
}

export default {
  GROUP_OF_ENTERPRISES_ALL,
  GROUP_OF_ENTERPRISES_CREATE,
  GROUP_OF_ENTERPRISES_READ,
  GROUP_OF_ENTERPRISES_UPDATE,
  GROUP_OF_ENTERPRISES_DELETE,
  BRAND_ALL,
  BRAND_CREATE,
  BRAND_READ,
  BRAND_UPDATE,
  BRAND_DELETE,
  COMPANY_ALL,
  COMPANY_CREATE,
  COMPANY_READ,
  COMPANY_UPDATE,
  COMPANY_DELETE,
  SUBSIDIARY_ALL,
  SUBSIDIARY_CREATE,
  SUBSIDIARY_READ,
  SUBSIDIARY_UPDATE,
  SUBSIDIARY_DELETE,
  DEPARTMENT_ALL,
  DEPARTMENT_CREATE,
  DEPARTMENT_READ,
  DEPARTMENT_UPDATE,
  DEPARTMENT_DELETE,
  DEPARTMENT_LEVEL_SETTING_ALL,
  DEPARTMENT_LEVEL_SETTING_CREATE,
  DEPARTMENT_LEVEL_SETTING_READ,
  DEPARTMENT_LEVEL_SETTING_UPDATE,
  DEPARTMENT_LEVEL_SETTING_DELETE,
};
