<template>
  <BContainer fluid>
    <BRow class="justify-content-center">
      <BCol md="12">
        <BCard no-body class="border-0 bg-colorset-transparent">
          <BCardBody>
            <slot name="main-content" />
          </BCardBody>
        </BCard>
      </BCol>
    </BRow>
  </BContainer>
</template>

<script>
export default {
  name: "MainContainer",
  mounted() {
    // 在組件掛載時創建 ResizeObserver
    this.observer = new ResizeObserver(() => {}); // 不執行任何操作
    this.observer.observe(this.$el); // 啟動 ResizeObserver 觀察器，觀察組件的根元素
  },
  beforeDestroy() {
    // 在組件銷毀前斷開 ResizeObserver
    if (this.observer) this.observer.disconnect();
  },
};
</script>
