// eslint-disable-next-line no-unused-vars
function isValidNone(id = null) {
  return true;
}

function isValidString(string) {
  return string !== null && string !== "" && string !== undefined;
}

function isValidNumber(string) {
  if (!string || isNaN(Number(string))) {
    return false;
  }
  return true;
}

function isValidDate(dateString) {
  var regex = /^\d{4}-\d{2}-\d{2}$/;

  if (!regex.test(dateString)) {
    return false;
  }

  var parts = dateString.split("-");
  var year = parseInt(parts[0], 10);
  var month = parseInt(parts[1], 10);
  var day = parseInt(parts[2], 10);

  // Check if month and day are valid
  if (month < 1 || month > 12 || day < 1 || day > 31) {
    return false;
  }

  // Check for February's days in case of leap year
  if (month == 2) {
    var isLeapYear = (year % 4 == 0 && year % 100 != 0) || year % 400 == 0;
    if (day > 29 || (day > 28 && !isLeapYear)) {
      return false;
    }
  }

  return true;
}

function isValidFile(file) {
  return file?.name !== null && file?.name !== "" && file?.name !== undefined;
}

function isValidCitizenId(citizenId) {
  const regex = /^[A-Z]\d{9}$/;
  return regex.test(citizenId);
}

function isValidTelephone(telephone) {
  const regex = /^\d{2}-\d{7,8}$/;
  return regex.test(telephone);
}

function isValidCellphone(cellphone) {
  const regex = /^\d{4}-\d{3}-\d{3}$/;
  return regex.test(cellphone);
}

function isValidEmail(email) {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
}

function isValidArmy(string) {
  string = String(string);
  if (!isValidString(string)) return false;
  const parts = string.split("，");
  parts[1] = "未完善";
  return (
    parts.length === 2 && isValidNumber(parts[0]) && isValidString(parts[1])
  );
}

function isValidAddress(address) {
  if (!isValidString(address)) return false;
  const parts = address.split("，");
  return (
    parts.length === 4 &&
    isValidNumber(parts[0]) &&
    isValidString(parts[1]) &&
    isValidString(parts[2]) &&
    isValidString(parts[3])
  );
}

export default {
  isValidNone,
  isValidString,
  isValidNumber,
  isValidFile,
  isValidDate,
  isValidCitizenId,
  isValidTelephone,
  isValidCellphone,
  isValidEmail,
  isValidArmy,
  isValidAddress,
};
