import store from "@/store";
import axios from "axios";
import {
  headers,
  replaceUrl,
  postRequestWithRetry,
  postCRUDRequest,
} from "@/api/apiServices/commonService";
import { API_ROUTES } from "@/api/apiConfig";
import REQUEST_MODELS from "@/api/requestModels/SystemManagement/permission";

const AllPermissionItemsByAccountID = {
  async getAll() {
    const token = store.state.auth.token;
    const accoundID = store.state.auth.userID;
    const reqModel = new REQUEST_MODELS.ALL_PERMISSION_ITEMS_BY_ACCOUNT_ID(
      token
    );
    const url = API_ROUTES.PERMISSION.ALL_PERMISSION_ITEMS_BY_ACCOUNT_ID;

    try {
      const response = await postRequestWithRetry(url, reqModel);
      return response[accoundID];
    } catch (error) {
      console.error("Error in AllPermissionItemsByAccountID: ", error);
    }
  },
};

const ActualGroupAndRelatedAccount = {
  async getAll() {
    const token = store.state.auth.token;
    const reqModel = new REQUEST_MODELS.ACTUAL_GROUP_AND_RELATED_ACCOUNT_ALL(
      token
    );
    const url = API_ROUTES.PERMISSION.ACTUAL_GROUP_AND_RELATED_ACCOUNT.ALL;
    try {
      const response = await postRequestWithRetry(url, reqModel);
      return response;
    } catch (error) {
      console.error("Error in ActualGroupAndRelatedAccount getAll: ", error);
    }
  },
  async create(requestData) {
    const reqModel = new REQUEST_MODELS.ACTUAL_GROUP_AND_RELATED_ACCOUNT_CREATE(
      requestData.token || "",
      requestData.permissionGroupId || "",
      requestData.accountId || ""
    );
    const url = API_ROUTES.PERMISSION.ACTUAL_GROUP_AND_RELATED_ACCOUNT.CREATE;
    return await postCRUDRequest(url, reqModel);
  },
  async delete(requestData) {
    const reqModel = new REQUEST_MODELS.ACTUAL_GROUP_AND_RELATED_ACCOUNT_DELETE(
      requestData.token || "",
      requestData.permissionGroupId || "",
      requestData.accountId || ""
    );
    const url = API_ROUTES.PERMISSION.ACTUAL_GROUP_AND_RELATED_ACCOUNT.DELETE;
    return await postCRUDRequest(url, reqModel);
  },
};

const ActualGroup = {
  async getAll() {
    const token = store.state.auth.token;
    const reqModel = new REQUEST_MODELS.ACTUAL_GROUP_ALL(token);
    const url = API_ROUTES.PERMISSION.ACTUAL_GROUP.ALL;
    try {
      const response = await postRequestWithRetry(url, reqModel);
      return response;
    } catch (error) {
      console.error("Error in ActualGroup getAll: ", error);
    }
  },
  async create(requestData) {
    const reqModel = new REQUEST_MODELS.ACTUAL_GROUP_CREATE(
      requestData.token || "",
      requestData.permissionGroupId || "",
      requestData.groupName || ""
    );
    const url = API_ROUTES.PERMISSION.ACTUAL_GROUP.CREATE;
    return await postCRUDRequest(url, reqModel);
  },
  async update(requestData) {
    const reqModel = new REQUEST_MODELS.ACTUAL_GROUP_UPDATE(
      requestData.token || "",
      requestData.permissionGroupId || "",
      requestData.groupName || ""
    );
    const url = API_ROUTES.PERMISSION.ACTUAL_GROUP.UPDATE;
    return await postCRUDRequest(url, reqModel);
  },
  async delete(requestData) {
    const reqModel = new REQUEST_MODELS.ACTUAL_GROUP_DELETE(
      requestData.token || "",
      requestData.permissionGroupId || "",
      requestData.groupName || ""
    );
    const url = API_ROUTES.PERMISSION.ACTUAL_GROUP.DELETE;
    return await postCRUDRequest(url, reqModel);
  },
};

const ActualItem = {
  async getAll() {
    const token = store.state.auth.token;
    const reqModel = new REQUEST_MODELS.ACTUAL_PERMISSION_ITEM_ALL(token);
    const url = API_ROUTES.PERMISSION.ACTUAL_ITEM.ALL;
    try {
      const response = await postRequestWithRetry(url, reqModel);
      return response;
    } catch (error) {
      console.error("Error in ActualItem getAll: ", error);
    }
  },
  async create(requestData) {
    const reqModel = new REQUEST_MODELS.ACTUAL_PERMISSION_ITEM_CREATE(
      requestData.token || "",
      requestData.permissionItemId || "",
      requestData.itemName || "",
      requestData.permissionGroupId || "",
      requestData.moduleId || "",
      requestData.menuId || "",
      requestData.functionId || "",
      requestData.itemType || 0,
      requestData.actionCreate || false,
      requestData.actionRead || false,
      requestData.actionUpdate || false,
      requestData.actionDelete || false
    );
    // console.log("requestData: ", requestData);
    // console.log("reqModel: ", reqModel);
    const url = API_ROUTES.PERMISSION.ACTUAL_ITEM.CREATE;
    return await postCRUDRequest(url, reqModel);
  },
  read(requestID) {
    const token = store.state.auth.token;
    const reqModel = new REQUEST_MODELS.ACTUAL_PERMISSION_ITEM_READ(token);
    let url = API_ROUTES.PERMISSION.ACTUAL_ITEM.READ;
    url = replaceUrl(url, "id", requestID);
    return axios
      .post(url, reqModel, {
        headers: headers,
      })
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        } else console.log(response.status);
      })
      .catch((error) => {
        console.error("Error in ActualItem: ", error);
      });
  },
  async update(requestData) {
    const reqModel = new REQUEST_MODELS.ACTUAL_PERMISSION_ITEM_UPDATE(
      requestData.token || "",
      requestData.permissionItemId || "",
      requestData.itemName || "",
      requestData.permissionGroupId || "",
      requestData.moduleId || "",
      requestData.menuId || "",
      requestData.functionId || "",
      requestData.itemType || 0,
      requestData.actionCreate || false,
      requestData.actionRead || false,
      requestData.actionUpdate || false,
      requestData.actionDelete || false
    );
    const url = API_ROUTES.PERMISSION.ACTUAL_ITEM.UPDATE;
    // console.log("requestData: ", requestData);
    // console.log("reqModel: ", reqModel);
    return await postCRUDRequest(url, reqModel);
  },
  async delete(requestData) {
    const reqModel = new REQUEST_MODELS.ACTUAL_PERMISSION_ITEM_DELETE(
      requestData.token || "",
      requestData.permissionItemId || "",
      requestData.itemName || "",
      requestData.permissionGroupId || "",
      requestData.moduleId || "",
      requestData.menuId || "",
      requestData.functionId || "",
      requestData.itemType || 0,
      requestData.actionCreate || false,
      requestData.actionRead || false,
      requestData.actionUpdate || false,
      requestData.actionDelete || false
    );
    const url = API_ROUTES.PERMISSION.ACTUAL_ITEM.DELETE;
    return await postCRUDRequest(url, reqModel);
  },
};

const TemplateGroup = {
  async getAll() {
    const token = store.state.auth.token;
    const reqModel = new REQUEST_MODELS.PERMISSION_TEMPLATE_ALL(token);
    const url = API_ROUTES.PERMISSION.TEMPLATE_GROUP.ALL;
    try {
      const response = await postRequestWithRetry(url, reqModel);
      return response;
    } catch (error) {
      console.error("Error in TemplateGroup getAll: ", error);
    }
  },
  async create(requestData) {
    const reqModel = new REQUEST_MODELS.PERMISSION_TEMPLATE_CREATE(
      requestData.token || "",
      requestData.permissionGroupId || "",
      requestData.groupName || ""
    );
    const url = API_ROUTES.PERMISSION.TEMPLATE_GROUP.CREATE;
    return await postCRUDRequest(url, reqModel);
  },
  async update(requestData) {
    const reqModel = new REQUEST_MODELS.PERMISSION_TEMPLATE_UPDATE(
      requestData.token || "",
      requestData.permissionGroupId || "",
      requestData.groupName || ""
    );
    const url = API_ROUTES.PERMISSION.TEMPLATE_GROUP.UPDATE;
    return await postCRUDRequest(url, reqModel);
  },
  async delete(requestData) {
    const reqModel = new REQUEST_MODELS.PERMISSION_TEMPLATE_DELETE(
      requestData.token || "",
      requestData.permissionGroupId || "",
      requestData.groupName || ""
    );
    const url = API_ROUTES.PERMISSION.TEMPLATE_GROUP.DELETE;
    return await postCRUDRequest(url, reqModel);
  },
};

const TemplateItem = {
  async getAll() {
    const token = store.state.auth.token;
    const reqModel = new REQUEST_MODELS.PERMISSION_ITEM_ALL(token);
    const url = API_ROUTES.PERMISSION.TEMPLATE_ITEM.ALL;
    try {
      const response = await postRequestWithRetry(url, reqModel);
      return response;
    } catch (error) {
      console.error("Error in TemplateItem getAll: ", error);
    }
  },
  async create(requestData) {
    const reqModel = new REQUEST_MODELS.PERMISSION_ITEM_CREATE(
      requestData.token || "",
      requestData.permissionItemId || "",
      requestData.itemName || "",
      requestData.permissionGroupId || "",
      requestData.moduleId || "",
      requestData.menuId || "",
      requestData.functionId || "",
      requestData.itemType || 0,
      requestData.actionCreate || false,
      requestData.actionRead || false,
      requestData.actionUpdate || false,
      requestData.actionDelete || false
    );
    // console.log("requestData: ", requestData);
    // console.log("reqModel: ", reqModel);
    const url = API_ROUTES.PERMISSION.TEMPLATE_ITEM.CREATE;
    try {
      const response = await axios.post(url, reqModel, {
        headers: headers,
      });
      return response.data;
    } catch (error) {
      console.error("Error in TemplateItem: ", error);
    }
  },
  async update(requestData) {
    const reqModel = new REQUEST_MODELS.PERMISSION_ITEM_UPDATE(
      requestData.token || "",
      requestData.permissionItemId || "",
      requestData.itemName || "",
      requestData.permissionGroupId || "",
      requestData.moduleId || "",
      requestData.menuId || "",
      requestData.functionId || "",
      requestData.itemType || 0,
      requestData.actionCreate || false,
      requestData.actionRead || false,
      requestData.actionUpdate || false,
      requestData.actionDelete || false
    );
    const url = API_ROUTES.PERMISSION.TEMPLATE_ITEM.UPDATE;
    return await postCRUDRequest(url, reqModel);
  },
  async delete(requestData) {
    const reqModel = new REQUEST_MODELS.PERMISSION_ITEM_DELETE(
      requestData.token || "",
      requestData.permissionItemId || "",
      requestData.itemName || "",
      requestData.permissionGroupId || "",
      requestData.moduleId || "",
      requestData.menuId || "",
      requestData.functionId || "",
      requestData.itemType || 0,
      requestData.actionCreate || false,
      requestData.actionRead || false,
      requestData.actionUpdate || false,
      requestData.actionDelete || false
    );
    const url = API_ROUTES.PERMISSION.TEMPLATE_ITEM.DELETE;
    return await postCRUDRequest(url, reqModel);
  },
};

const SysModule = {
  async getAll() {
    const token = store.state.auth.token;
    const reqModel = new REQUEST_MODELS.SYS_MODULE_ALL(token);
    const url = API_ROUTES.PERMISSION.SYS_MODULE.ALL;
    try {
      const response = await postRequestWithRetry(url, reqModel);
      return response;
    } catch (error) {
      console.error("Error in SysModule: ", error);
    }
  },
  async create(requestData) {
    const reqModel = new REQUEST_MODELS.SYS_MODULE_CREATE(
      requestData.token || "",
      requestData.id || "",
      requestData.twName || "",
      requestData.enName || "",
      requestData.shortCode || "",
      requestData.hierarchy || "",
      requestData.uri || ""
    );
    const url = API_ROUTES.PERMISSION.SYS_MODULE.CREATE;
    return await postCRUDRequest(url, reqModel);
  },
  read(requestID) {
    const token = store.state.auth.token;
    const reqModel = new REQUEST_MODELS.SYS_MODULE_READ(token);
    let url = API_ROUTES.PERMISSION.SYS_MODULE.READ;
    url = replaceUrl(url, "id", requestID);
    return axios
      .post(url, reqModel, {
        headers: headers,
      })
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        } else console.log(response.status);
      })
      .catch((error) => {
        const { response } = error;
        throw new Error(response.data.msg);
      });
  },
  async update(requestData) {
    const reqModel = new REQUEST_MODELS.SYS_MODULE_UPDATE(
      requestData.token || "",
      requestData.id || "",
      requestData.twName || "",
      requestData.enName || "",
      requestData.shortCode || "",
      requestData.hierarchy || "",
      requestData.uri || ""
    );
    const url = API_ROUTES.PERMISSION.SYS_MODULE.UPDATE;
    return await postCRUDRequest(url, reqModel);
  },
  async delete(requestData) {
    const reqModel = new REQUEST_MODELS.SYS_MODULE_DELETE(
      requestData.token || "",
      requestData.id || "",
      requestData.twName || "",
      requestData.enName || "",
      requestData.shortCode || "",
      requestData.hierarchy || "",
      requestData.uri || ""
    );
    const url = API_ROUTES.PERMISSION.SYS_MODULE.DELETE;
    return await postCRUDRequest(url, reqModel);
  },
};

const SysMenu = {
  async getAll() {
    const token = store.state.auth.token;
    const reqModel = new REQUEST_MODELS.SYS_MENU_ALL(token);
    const url = API_ROUTES.PERMISSION.SYS_MENU.ALL;
    try {
      const response = await postRequestWithRetry(url, reqModel);
      return response;
    } catch (error) {
      console.error("Error in SysMenu: ", error);
    }
  },
  async create(requestData) {
    const reqModel = new REQUEST_MODELS.SYS_MENU_CREATE(
      requestData.token || "",
      requestData.id || "",
      requestData.twName || "",
      requestData.enName || "",
      requestData.shortCode || "",
      requestData.hierarchy || "",
      requestData.uri || "",
      requestData.sysModuleId || ""
    );
    const url = API_ROUTES.PERMISSION.SYS_MENU.CREATE;
    return await postCRUDRequest(url, reqModel);
  },
  read(requestID) {
    const token = store.state.auth.token;
    const reqModel = new REQUEST_MODELS.SYS_MENU_READ(token);
    let url = API_ROUTES.PERMISSION.SYS_MENU.READ;
    url = replaceUrl(url, "id", requestID);
    return axios
      .post(url, reqModel, {
        headers: headers,
      })
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        } else console.log(response.status);
      })
      .catch((error) => {
        console.error("Error in SysMenu: ", error);
      });
  },
  async update(requestData) {
    const reqModel = new REQUEST_MODELS.SYS_MENU_UPDATE(
      requestData.token || "",
      requestData.id || "",
      requestData.twName || "",
      requestData.enName || "",
      requestData.shortCode || "",
      requestData.hierarchy || "",
      requestData.uri || "",
      requestData.sysModuleId || ""
    );
    const url = API_ROUTES.PERMISSION.SYS_MENU.UPDATE;
    return await postCRUDRequest(url, reqModel);
  },
  async delete(requestData) {
    const reqModel = new REQUEST_MODELS.SYS_MENU_DELETE(
      requestData.token || "",
      requestData.id || "",
      requestData.twName || "",
      requestData.enName || "",
      requestData.shortCode || "",
      requestData.hierarchy || "",
      requestData.uri || "",
      requestData.sysModuleId || ""
    );
    const url = API_ROUTES.PERMISSION.SYS_MENU.DELETE;
    return await postCRUDRequest(url, reqModel);
  },
};

const SysFunction = {
  async getAll() {
    const token = store.state.auth.token;
    const reqModel = new REQUEST_MODELS.SYS_FUNCTION_ALL(token);
    const url = API_ROUTES.PERMISSION.SYS_FUNCTION.ALL;
    try {
      const response = await postRequestWithRetry(url, reqModel);
      return response;
    } catch (error) {
      console.error("Error in SysFunction getAll: ", error);
    }
  },
  async create(requestData) {
    const reqModel = new REQUEST_MODELS.SYS_FUNCTION_CREATE(
      requestData.token || "",
      requestData.id || "",
      requestData.twName || "",
      requestData.enName || "",
      requestData.shortCode || "",
      requestData.hierarchy || "",
      requestData.uri || "",
      requestData.sysMenuId || "",
      requestData.actionCreate || false,
      requestData.actionRead || false,
      requestData.actionUpdate || false,
      requestData.actionDelete || false
    );
    // console.log("requestData: ", requestData);
    // console.log("reqModel: ", reqModel);
    const url = API_ROUTES.PERMISSION.SYS_FUNCTION.CREATE;
    return await postCRUDRequest(url, reqModel);
  },
  async read(requestID) {
    const token = store.state.auth.token;
    const reqModel = new REQUEST_MODELS.SYS_FUNCTION_READ(token);
    let url = API_ROUTES.PERMISSION.SYS_FUNCTION.READ;
    url = replaceUrl(url, "id", requestID);
    return axios
      .post(url, reqModel, {
        headers: headers,
      })
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        } else console.log(response.status);
      })
      .catch((error) => {
        const { response } = error;
        throw new Error(response.data.msg);
      });
  },
  async update(requestData) {
    const reqModel = new REQUEST_MODELS.SYS_FUNCTION_UPDATE(
      requestData.token || "",
      requestData.id || "",
      requestData.twName || "",
      requestData.enName || "",
      requestData.shortCode || "",
      requestData.hierarchy || "",
      requestData.uri || "",
      requestData.sysMenuId || "",
      requestData.actionCreate || false,
      requestData.actionRead || false,
      requestData.actionUpdate || false,
      requestData.actionDelete || false
    );
    // console.log("requestData: ", requestData);
    // console.log("reqModel: ", reqModel);
    const url = API_ROUTES.PERMISSION.SYS_FUNCTION.UPDATE;
    return await postCRUDRequest(url, reqModel);
  },
  async delete(requestData) {
    const reqModel = new REQUEST_MODELS.SYS_FUNCTION_DELETE(
      requestData.token || "",
      requestData.id || "",
      requestData.twName || "",
      requestData.enName || "",
      requestData.shortCode || "",
      requestData.hierarchy || "",
      requestData.uri || "",
      requestData.sysMenuId || "",
      requestData.actionCreate || false,
      requestData.actionRead || false,
      requestData.actionUpdate || false,
      requestData.actionDelete || false
    );
    const url = API_ROUTES.PERMISSION.SYS_FUNCTION.DELETE;
    return await postCRUDRequest(url, reqModel);
  },
};

export default {
  AllPermissionItemsByAccountID,
  ActualGroupAndRelatedAccount,
  ActualGroup,
  ActualItem,
  TemplateGroup,
  TemplateItem,
  SysModule,
  SysMenu,
  SysFunction,
};
