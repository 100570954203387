export default {
  methods: {
    async initializePage() {
      if (typeof this.refreshData === "function") await this.refreshData();
      if (typeof this.updateOptionOfColumns === "function") {
        // 由於初始化時無法獲取數據，需要在組件創建時補充數據。
        await this.updateOptionOfColumns();
      }
    },
  },
  watch: {
    async "$store.state.SystemManagement.isPermissionApiCalled"(newValue) {
      if (!newValue) return;
      await this.initializePage();
    },
  },
  async created() {
    if (this.$store.state.SystemManagement.isPermissionApiCalled) {
      await this.initializePage();
    }
  },
};
