import store from "@/store";
import axios from "axios";
import {
  headers,
  replaceUrl,
  postRequestWithRetry,
  postCRUDRequest,
} from "@/api/apiServices/commonService";
import { API_ROUTES } from "@/api/apiConfig";
import REQUEST_MODELS from "@/api/requestModels/SystemManagement/corporation";

const Corporation = {
  async getAll(requestPermissionID) {
    const token = store.state.auth.token;
    const reqModel = new REQUEST_MODELS.GROUP_OF_ENTERPRISES_ALL(token);
    let url = API_ROUTES.CORPORATION.CORPORATION.ALL;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    try {
      const response = await postRequestWithRetry(url, reqModel);
      return response;
    } catch (error) {
      console.error("Error in Corporation getAll: ", error);
    }
  },
  async create(requestData, requestPermissionID) {
    const reqModel = new REQUEST_MODELS.GROUP_OF_ENTERPRISES_CREATE(
      requestData.token || "",
      requestData.serialNo || 0,
      requestData.groupName || "",
      requestData.groupBossName || "",
      requestData.note || ""
    );
    // console.log("requestData: ", requestData);
    // console.log("reqModel: ", reqModel);
    let url = API_ROUTES.CORPORATION.CORPORATION.CREATE;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    return await postCRUDRequest(url, reqModel);
  },
  async read(requestID) {
    const token = store.state.auth.token;
    const reqModel = new REQUEST_MODELS.GROUP_OF_ENTERPRISES_READ(token);
    let url = API_ROUTES.CORPORATION.CORPORATION.READ;
    url = replaceUrl(url, "id", requestID);
    try {
      const response = await axios.post(url, reqModel, {
        headers: headers,
      });
      return response.data;
    } catch (error) {
      console.error("Error in Corporation: ", error);
    }
  },
  async update(requestData, requestPermissionID) {
    const reqModel = new REQUEST_MODELS.GROUP_OF_ENTERPRISES_UPDATE(
      requestData.token || "",
      requestData.serialNo || 0,
      requestData.groupName || "",
      requestData.groupBossName || "",
      requestData.note || ""
    );
    // console.log("requestData: ", requestData);
    // console.log("reqModel: ", reqModel);
    let url = API_ROUTES.CORPORATION.CORPORATION.UPDATE;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    return await postCRUDRequest(url, reqModel);
  },
  async delete(requestData, requestPermissionID) {
    const reqModel = new REQUEST_MODELS.GROUP_OF_ENTERPRISES_DELETE(
      requestData.token || "",
      requestData.serialNo || 0,
      requestData.groupName || "",
      requestData.groupBossName || "",
      requestData.note || ""
    );
    // console.log("requestData: ", requestData);
    // console.log("reqModel: ", reqModel);
    let url = API_ROUTES.CORPORATION.CORPORATION.DELETE;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    return await postCRUDRequest(url, reqModel);
  },
};

const Brand = {
  async getAll(requestPermissionID) {
    const token = store.state.auth.token;
    const reqModel = new REQUEST_MODELS.BRAND_ALL(token);
    let url = API_ROUTES.CORPORATION.BRAND.ALL;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    try {
      const response = await postRequestWithRetry(url, reqModel);
      return response;
    } catch (error) {
      console.error("Error in Brand getAll: ", error);
    }
  },
  async create(requestData, requestPermissionID) {
    const reqModel = new REQUEST_MODELS.BRAND_CREATE(
      requestData.token || "",
      requestData.serialNo || 0,
      requestData.groupId || 0,
      requestData.chName || "",
      requestData.enName || ""
    );
    // console.log("requestData: ", requestData);
    // console.log("reqModel: ", reqModel);
    let url = API_ROUTES.CORPORATION.BRAND.CREATE;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    return await postCRUDRequest(url, reqModel);
  },
  async read(requestID) {
    const token = store.state.auth.token;
    const reqModel = new REQUEST_MODELS.BRAND_READ(token);
    let url = API_ROUTES.CORPORATION.BRAND.READ;
    url = replaceUrl(url, "id", requestID);
    try {
      const response = await axios.post(url, reqModel, {
        headers: headers,
      });
      return response.data;
    } catch (error) {
      console.error("Error in Brand: ", error);
    }
  },
  async update(requestData, requestPermissionID) {
    const reqModel = new REQUEST_MODELS.BRAND_UPDATE(
      requestData.token || "",
      requestData.serialNo || 0,
      requestData.groupId || 0,
      requestData.chName || "",
      requestData.enName || ""
    );
    // console.log("requestData: ", requestData);
    // console.log("reqModel: ", reqModel);
    let url = API_ROUTES.CORPORATION.BRAND.UPDATE;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    return await postCRUDRequest(url, reqModel);
  },
  async delete(requestData, requestPermissionID) {
    const reqModel = new REQUEST_MODELS.BRAND_DELETE(
      requestData.token || "",
      requestData.serialNo || 0,
      requestData.groupId || 0,
      requestData.chName || "",
      requestData.enName || ""
    );
    // console.log("requestData: ", requestData);
    // console.log("reqModel: ", reqModel);
    let url = API_ROUTES.CORPORATION.BRAND.DELETE;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    return await postCRUDRequest(url, reqModel);
  },
};

const Company = {
  async getAll(requestPermissionID) {
    const token = store.state.auth.token;
    const reqModel = new REQUEST_MODELS.COMPANY_ALL(token);
    let url = API_ROUTES.CORPORATION.COMPANY.ALL;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    try {
      const response = await postRequestWithRetry(url, reqModel);
      return response;
    } catch (error) {
      console.error("Error in Company getAll: ", error);
    }
  },
  async create(requestData, requestPermissionID) {
    const reqModel = new REQUEST_MODELS.COMPANY_CREATE(
      requestData.token || "",
      requestData.serialNo || 0,
      requestData.brandId || 0,
      requestData.chName || "",
      requestData.enName || "",
      requestData.upstreamCompany || 0,
      requestData.companyType || 0,
      requestData.publicRegistrationCode || "",
      requestData.representative || "",
      requestData.surrogate || "",
      requestData.telephone1 || "",
      requestData.telephone2 || "",
      requestData.fax || "",
      requestData.registrationAddress || "",
      requestData.contactAddress || "",
      requestData.contact || "",
      requestData.contactEmail || ""
    );
    // console.log("requestData: ", requestData);
    // console.log("reqModel: ", reqModel);
    let url = API_ROUTES.CORPORATION.COMPANY.CREATE;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    return await postCRUDRequest(url, reqModel);
  },
  async read(requestID) {
    const token = store.state.auth.token;
    const reqModel = new REQUEST_MODELS.COMPANY_READ(token);
    const url = API_ROUTES.CORPORATION.COMPANY.READ.replace(
      /{([^}]+)}/,
      (match, capturedGroup) => {
        if (capturedGroup === "id") return requestID;
      }
    );
    try {
      const response = await axios.post(url, reqModel, {
        headers: headers,
      });
      return response.data;
    } catch (error) {
      console.error("Error in Company: ", error);
    }
  },
  async update(requestData, requestPermissionID) {
    const reqModel = new REQUEST_MODELS.COMPANY_UPDATE(
      requestData.token || "",
      requestData.serialNo || 0,
      requestData.brandId || 0,
      requestData.chName || "",
      requestData.enName || "",
      requestData.upstreamCompany || 0,
      requestData.companyType || 0,
      requestData.publicRegistrationCode || "",
      requestData.representative || "",
      requestData.surrogate || "",
      requestData.telephone1 || "",
      requestData.telephone2 || "",
      requestData.fax || "",
      requestData.registrationAddress || "",
      requestData.contactAddress || "",
      requestData.contact || "",
      requestData.contactEmail || ""
    );
    // console.log("requestData: ", requestData);
    // console.log("reqModel: ", reqModel);
    let url = API_ROUTES.CORPORATION.COMPANY.UPDATE;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    return await postCRUDRequest(url, reqModel);
  },
  async delete(requestData, requestPermissionID) {
    const reqModel = new REQUEST_MODELS.COMPANY_DELETE(
      requestData.token || "",
      requestData.serialNo || 0,
      requestData.brandId || 0,
      requestData.chName || "",
      requestData.enName || "",
      requestData.upstreamCompany || 0,
      requestData.companyType || 0,
      requestData.publicRegistrationCode || "",
      requestData.representative || "",
      requestData.surrogate || "",
      requestData.telephone1 || "",
      requestData.telephone2 || "",
      requestData.fax || "",
      requestData.registrationAddress || "",
      requestData.contactAddress || "",
      requestData.contact || "",
      requestData.contactEmail || ""
    );
    // console.log("requestData: ", requestData);
    // console.log("reqModel: ", reqModel);
    let url = API_ROUTES.CORPORATION.COMPANY.DELETE;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    return await postCRUDRequest(url, reqModel);
  },
};

const Subsidiary = {
  async getAll(requestPermissionID) {
    const token = store.state.auth.token;
    const reqModel = new REQUEST_MODELS.SUBSIDIARY_ALL(token);
    let url = API_ROUTES.CORPORATION.SUBSIDIARY.ALL;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    try {
      const response = await postRequestWithRetry(url, reqModel);
      return response;
    } catch (error) {
      console.error("Error in Subsidiary getAll: ", error);
    }
  },
  async create(requestData, requestPermissionID) {
    const reqModel = new REQUEST_MODELS.SUBSIDIARY_CREATE(
      requestData.token || "",
      requestData.serialNo || 0,
      requestData.companyId || 0,
      requestData.chName || "",
      requestData.enName || "",
      requestData.publicRegistrationCode || "",
      requestData.representative || "",
      requestData.surrogate || "",
      requestData.telephone1 || "",
      requestData.telephone2 || "",
      requestData.fax || "",
      requestData.registrationAddress || "",
      requestData.contactAddress || "",
      requestData.contact || "",
      requestData.contactEmail || ""
    );
    // console.log("requestData: ", requestData);
    // console.log("reqModel: ", reqModel);
    let url = API_ROUTES.CORPORATION.SUBSIDIARY.CREATE;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    return await postCRUDRequest(url, reqModel);
  },
  async read(requestID) {
    const token = store.state.auth.token;
    const reqModel = new REQUEST_MODELS.SUBSIDIARY_READ(token);
    const url = API_ROUTES.CORPORATION.SUBSIDIARY.READ.replace(
      /{([^}]+)}/,
      (match, capturedGroup) => {
        if (capturedGroup === "id") return requestID;
      }
    );
    try {
      const response = await axios.post(url, reqModel, {
        headers: headers,
      });
      return response.data;
    } catch (error) {
      console.error("Error in Subsidiary: ", error);
    }
  },
  async update(requestData, requestPermissionID) {
    const reqModel = new REQUEST_MODELS.SUBSIDIARY_UPDATE(
      requestData.token || "",
      requestData.serialNo || 0,
      requestData.companyId || 0,
      requestData.chName || "",
      requestData.enName || "",
      requestData.publicRegistrationCode || "",
      requestData.representative || "",
      requestData.surrogate || "",
      requestData.telephone1 || "",
      requestData.telephone2 || "",
      requestData.fax || "",
      requestData.registrationAddress || "",
      requestData.contactAddress || "",
      requestData.contact || "",
      requestData.contactEmail || ""
    );
    // console.log("requestData: ", requestData);
    // console.log("reqModel: ", reqModel);
    let url = API_ROUTES.CORPORATION.SUBSIDIARY.UPDATE;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    return await postCRUDRequest(url, reqModel);
  },
  async delete(requestData, requestPermissionID) {
    const reqModel = new REQUEST_MODELS.SUBSIDIARY_DELETE(
      requestData.token || "",
      requestData.serialNo || 0,
      requestData.companyId || 0,
      requestData.chName || "",
      requestData.enName || "",
      requestData.publicRegistrationCode || "",
      requestData.representative || "",
      requestData.surrogate || "",
      requestData.telephone1 || "",
      requestData.telephone2 || "",
      requestData.fax || "",
      requestData.registrationAddress || "",
      requestData.contactAddress || "",
      requestData.contact || "",
      requestData.contactEmail || ""
    );
    // console.log("requestData: ", requestData);
    // console.log("reqModel: ", reqModel);
    let url = API_ROUTES.CORPORATION.SUBSIDIARY.DELETE;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    return await postCRUDRequest(url, reqModel);
  },
};

const Department = {
  async getAll(requestPermissionID) {
    const token = store.state.auth.token;
    const reqModel = new REQUEST_MODELS.DEPARTMENT_ALL(token);
    let url = API_ROUTES.CORPORATION.DEPARTMENT.ALL;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    try {
      const response = await postRequestWithRetry(url, reqModel);
      return response;
    } catch (error) {
      console.error("Error in Department getAll: ", error);
    }
  },
  async create(requestData, requestPermissionID) {
    const reqModel = new REQUEST_MODELS.DEPARTMENT_CREATE(
      requestData.token || "",
      requestData.serialNo || 0,
      requestData.companyId || 0,
      requestData.departmentId || "",
      requestData.chName || "",
      requestData.enName || "",
      requestData.managerId || "",
      requestData.levelSerialNo || 0,
      requestData.upstreamDepartmentId || "",
      requestData.isStore || false
    );
    let url = API_ROUTES.CORPORATION.DEPARTMENT.CREATE;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    console.log("reqModel: ", reqModel, url);
    return await postCRUDRequest(url, reqModel);
  },
  async read(requestID) {
    const token = store.state.auth.token;
    const reqModel = new REQUEST_MODELS.DEPARTMENT_READ(token);
    const url = API_ROUTES.CORPORATION.DEPARTMENT.READ.replace(
      /{([^}]+)}/,
      (match, capturedGroup) => {
        if (capturedGroup === "id") return requestID;
      }
    );
    try {
      const response = await axios.post(url, reqModel, {
        headers: headers,
      });
      return response.data;
    } catch (error) {
      console.error("Error in Department: ", error);
    }
  },
  async update(requestData, requestPermissionID) {
    const reqModel = new REQUEST_MODELS.DEPARTMENT_UPDATE(
      requestData.token || "",
      requestData.serialNo || 0,
      requestData.companyId || 0,
      requestData.departmentId || "",
      requestData.chName || "",
      requestData.enName || "",
      requestData.managerId || "",
      requestData.levelSerialNo || 0,
      requestData.upstreamDepartmentId || "",
      requestData.isStore || false
    );
    // console.log("requestData: ", requestData);
    // console.log("reqModel: ", reqModel);
    let url = API_ROUTES.CORPORATION.DEPARTMENT.UPDATE;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    return await postCRUDRequest(url, reqModel);
  },
  async delete(requestData, requestPermissionID) {
    const reqModel = new REQUEST_MODELS.DEPARTMENT_DELETE(
      requestData.token || "",
      requestData.serialNo || 0,
      requestData.companyId || 0,
      requestData.departmentId || "",
      requestData.chName || "",
      requestData.enName || "",
      requestData.managerId || "",
      requestData.levelSerialNo || 0,
      requestData.upstreamDepartmentId || "",
      requestData.isStore || false
    );
    // console.log("requestData: ", requestData);
    // console.log("reqModel: ", reqModel);
    let url = API_ROUTES.CORPORATION.DEPARTMENT.DELETE;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    return await postCRUDRequest(url, reqModel);
  },
};

const DepartmentLevelSetting = {
  async getAll(requestPermissionID) {
    const token = store.state.auth.token;
    const reqModel = new REQUEST_MODELS.DEPARTMENT_LEVEL_SETTING_ALL(token);
    let url = API_ROUTES.CORPORATION.DEPARTMENT_LEVEL_SETTING.ALL;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    try {
      const response = await postRequestWithRetry(url, reqModel);
      return response;
    } catch (error) {
      console.error("Error in DepartmentLevelSetting getAll: ", error);
    }
  },
  async create(requestData, requestPermissionID) {
    const reqModel = new REQUEST_MODELS.DEPARTMENT_LEVEL_SETTING_CREATE(
      requestData.token || "",
      requestData.serialNo || 0,
      requestData.lv || 0,
      requestData.levelName || "",
      requestData.upstreamId || 0,
      requestData.levelProperty || ""
    );
    // console.log("requestData: ", requestData);
    // console.log("reqModel: ", reqModel);
    let url = API_ROUTES.CORPORATION.DEPARTMENT_LEVEL_SETTING.CREATE;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    return await postCRUDRequest(url, reqModel);
  },
  async read(requestID) {
    const token = store.state.auth.token;
    const reqModel = new REQUEST_MODELS.DEPARTMENT_LEVEL_SETTING_READ(token);
    const url = API_ROUTES.CORPORATION.DEPARTMENT_LEVEL_SETTING.READ.replace(
      /{([^}]+)}/,
      (match, capturedGroup) => {
        if (capturedGroup === "id") return requestID;
      }
    );
    try {
      const response = await axios.post(url, reqModel, {
        headers: headers,
      });
      return response.data;
    } catch (error) {
      console.error("Error in DepartmentLevelSetting: ", error);
    }
  },
  async update(requestData, requestPermissionID) {
    const reqModel = new REQUEST_MODELS.DEPARTMENT_LEVEL_SETTING_UPDATE(
      requestData.token || "",
      requestData.serialNo || 0,
      requestData.lv || 0,
      requestData.levelName || "",
      requestData.upstreamId || 0,
      requestData.levelProperty || ""
    );
    // console.log("requestData: ", requestData);
    // console.log("reqModel: ", reqModel);
    let url = API_ROUTES.CORPORATION.DEPARTMENT_LEVEL_SETTING.UPDATE;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    return await postCRUDRequest(url, reqModel);
  },
  async delete(requestData, requestPermissionID) {
    const reqModel = new REQUEST_MODELS.DEPARTMENT_LEVEL_SETTING_DELETE(
      requestData.token || "",
      requestData.serialNo || 0,
      requestData.lv || 0,
      requestData.levelName || "",
      requestData.upstreamId || 0,
      requestData.levelProperty || ""
    );
    // console.log("requestData: ", requestData);
    // console.log("reqModel: ", reqModel);
    let url = API_ROUTES.CORPORATION.DEPARTMENT_LEVEL_SETTING.DELETE;
    url = replaceUrl(url, "permissionId", requestPermissionID);
    return await postCRUDRequest(url, reqModel);
  },
};

export default {
  Corporation,
  Brand,
  Company,
  Subsidiary,
  Department,
  DepartmentLevelSetting,
};
