export const sharedOptions = {
  itemType: [
    { id: 1, name: "模組" },
    { id: 2, name: "選單" },
    { id: 3, name: "功能" },
  ],
  jobTitleId: [
    { id: 1, name: "一般同仁" },
    { id: 2, name: "副店長" },
    { id: 3, name: "代理店長" },
    { id: 4, name: "店長" },
    { id: 5, name: "組長" },
    { id: 6, name: "課長" },
    { id: 7, name: "技術長" },
    { id: 8, name: "主任" },
    { id: 9, name: "代理主任" },
    { id: 10, name: "副理" },
    { id: 11, name: "經理" },
    { id: 12, name: "特助" },
    { id: 13, name: "本部長" },
    { id: 14, name: "副總經理" },
    { id: 15, name: "總經理" },
    { id: 16, name: "董事長" },
  ],
  type: [
    { id: 1, name: "群組範本" },
    { id: 2, name: "職掌範本" },
  ],
};
