<template>
  <div>
    <div>
      <h4 class="ms-4 ps-2 my-2 font-weight-bold fw-bolder">
        <BIcon
          v-if="functionRoutenameIconPair[$route.name]"
          :icon="functionRoutenameIconPair[$route.name]"
          class="me-3"
        />
        <!-- <code>{{ functionRoutenameIconPair[$route.name] }}</code> -->
        <span>{{ header }}</span>
      </h4>
    </div>
    <router-view />
  </div>
</template>

<script>
import ROUTENAME_ICON_PAIR from "@/router/routenameIconPair";

export default {
  name: "SystemManagementLayout",
  data() {
    return {
      functionRoutenameIconPair: ROUTENAME_ICON_PAIR.functionRoutenameIconPair,
    };
  },
  computed: {
    header() {
      return this.$route.meta.title.split(" - ")[0];
    },
  },
  mounted() {},
};
</script>

<style scoped></style>
